import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "bootstrap/dist/js/bootstrap.min.js";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";

const Home = React.lazy(() => import("./Home"));
const AboutHistory = React.lazy(() => import("./components/AboutHistory"));

const AdministrativeStaff = React.lazy(() =>
  import("./components/AdministrativeStaff")
);
const Aboutpage = React.lazy(() => import("./components/Aboutpage"));
const AboutVisionMission = React.lazy(() =>
  import("./components/AboutVisionMission")
);
const KMEnrollmentFund = React.lazy(() =>
  import("./components/KMEnrollmentFund")
);
const AdmissionProcedure = React.lazy(() =>
  import("./components/AdmissionProcedure")
);
const Admissioncontact = React.lazy(() =>
  import("./components/Admissioncontact")
);
const Admissionpage = React.lazy(() => import("./components/Admissionpage"));
const Blogpage = React.lazy(() => import("./components/Blogpage"));
const Principalpage = React.lazy(() => import("./components/Principalpage"));
const DirectorPage = React.lazy(() => import("./components/DirectorPage"));
const Principalmessage = React.lazy(() =>
  import("./components/Principalmessage")
);
const Momentous = React.lazy(() => import("./components/Momentous"));
const Management = React.lazy(() => import("./components/Management"));
const Results = React.lazy(() => import("./components/Results"));
const ExaminationRules = React.lazy(() =>
  import("./components/ExamRulesRegulations")
);
const ExaminationGoveringbody = React.lazy(() =>
  import("./components/Governingbodies")
);
const ExaminationContact = React.lazy(() => import("./components/ExamContact"));

const Hostel = React.lazy(() => import("./components/facilities/Hostel"));
const CurriculaAndSyllabi = React.lazy(() =>
  import("./components/CurriculaAndSyllabi")
);
const Civildept = React.lazy(() =>
  import("./components/civil/civilengineering")
);
const Civilobjective = React.lazy(() =>
  import("./components/civil/educationalobjectives")
);
const VisionAndMissioncivil = React.lazy(() =>
  import("./components/civil/VisionAndMissioncivil")
);
const PeoPsOcivil = React.lazy(() => import("./components/civil/PeoPsOcivil"));

const Civiloutcomes = React.lazy(() =>
  import("./components/civil/specificoutcomes")
);
const Civilprogrammeoutcomes = React.lazy(() =>
  import("./components/civil/programmeoutcomes")
);
const Civilsyllabus = React.lazy(() =>
  import("./components/civil/curriculumsyllabus")
);
const CivilSemesterPlanner = React.lazy(() =>
  import("./components/civil/CivilSemesterPlanner")
);
const MoUSignedcivil = React.lazy(() =>
  import("./components/civil/MoUSignedcivil")
);
const CivilProgramAdvisoryCommittee = React.lazy(() =>
  import("./components/civil/ProgramAdvisoryCommittee")
);

const Civilfaculty = React.lazy(() => import("./components/civil/faculties"));
const Civilplacement = React.lazy(() => import("./components/civil/placement"));
const Mech = React.lazy(() => import("./components/mech/mech"));
const Mechobjective = React.lazy(() =>
  import("./components/mech/educationalobjectives")
);
const MechssVisionAndMission = React.lazy(() =>
  import("./components/mech/MechssVisionAndMission")
);

const MechssPeoPsO = React.lazy(() => import("./components/mech/MechssPeoPsO"));

const Mechsyllabus = React.lazy(() =>
  import("./components/mech/curriculumsyllabus")
);
const Mechssfaculty = React.lazy(() =>
  import("./components/mech/Mechssfaculty")
);
const MechssPlacement = React.lazy(() =>
  import("./components/mech/MechssPlacement")
);
const EEE = React.lazy(() => import("./components/eee/eee"));

const EEEVisionAndMission = React.lazy(() =>
  import("./components/eee/EEEVisionAndMission")
);

const EEESemesterPlanner = React.lazy(() =>
  import("./components/eee/SemesterPlanner")
);

const EEEPeoPsO = React.lazy(() => import("./components/eee/EEEPeoPsO"));
const EEEsyllabus = React.lazy(() =>
  import("./components/eee/curriculumsyllabus")
);
const EEEfaculty = React.lazy(() => import("./components/eee/faculties"));
const EEElab = React.lazy(() => import("./components/eee/EEElab"));
const PE = React.lazy(() => import("./components/pe/pe"));

const PeoPsOPE = React.lazy(() => import("./components/pe/PeoPsOPE"));
const PeMoUSigned = React.lazy(() => import("./components/pe/PeMoUSigned"));
const PEPAC = React.lazy(() => import("./components/pe/PEPAC"));
const PeVisionAndMission = React.lazy(() =>
  import("./components/pe/PeVisionAndMission")
);

const PESemesterPlanner = React.lazy(() =>
  import("./components/pe/PESemesterPlanner")
);

const PeNewsLetter = React.lazy(() =>
  import("./components/pe/PeNewsLetter")
);

const PePublications = React.lazy(() =>
  import("./components/pe/PePublications")
);

const PECOE = React.lazy(() => import("./components/pe/PECOE"));
const PEfaculty = React.lazy(() => import("./components/pe/faculties"));
const PEplacement = React.lazy(() => import("./components/pe/PEplacement"));

const Textile = React.lazy(() => import("./components/textile/textile"));
const TextilePAC = React.lazy(() => import("./components/textile/ProgramAdvisoryCommittee"));

const PeoPsotex = React.lazy(() => import("./components/textile/PeoPsotex"));
const Textilesyllabus = React.lazy(() =>
  import("./components/textile/curriculumsyllabus")
);
const Textilefaculty = React.lazy(() =>
  import("./components/textile/faculties")
);
const TexPlacement = React.lazy(() => import("./components/textile/placement"));
const Textileplacement = React.lazy(() =>
  import("./components/textile/placement")
);
const VisionandMissiontex = React.lazy(() =>
  import("./components/textile/VisionandMissiontex")
);

const CS = React.lazy(() => import("./components/cs/cs"));
const CSobjective = React.lazy(() =>
  import("./components/cs/educationalobjectives")
);
const CSoutcomes = React.lazy(() => import("./components/cs/specificoutcomes"));
const CSsyllabus = React.lazy(() =>
  import("./components/cs/curriculumsyllabus")
);
const CSfaculty = React.lazy(() => import("./components/cs/faculties"));
const CSSemesterPlanner = React.lazy(() =>
  import("./components/cs/SemesterPlanner")
);
const CsMoUSigned = React.lazy(() => import("./components/cs/CsMoUSigned"));
const CSplacement = React.lazy(() => import("./components/cs/placement"));
const CSProgramAdvisoryCommittee = React.lazy(() =>
  import("./components/cs/ProgramAdvisoryCommittee")
);
const CSAcademicCalendar = React.lazy(() =>
  import("./components/cs/CSAcademicCalendar")
);
const VisionAndMission = React.lazy(() =>
  import("./components/cs/VisionAndMission")
);
const FeedbackRegarding = React.lazy(() =>
  import("./components/cs/FeedbackRegarding")
);
const PeoPsO = React.lazy(() => import("./components/cs/PeoPsO"));
const MoUSignedtex = React.lazy(() =>
  import("./components/textile/MoUSignedtex")
);
const Texpub = React.lazy(() =>
  import("./components/textile/Texpub")
);
const Texlab = React.lazy(() => import("./components/textile/Texlab"));
const TexNewsletter = React.lazy(() =>
  import("./components/textile/TexNewsletter")
);
// const Texcoe = React.lazy(() =>
//   import("./components/textile/Texcoe")
// );
const ECE = React.lazy(() => import("./components/ece/ece"));
const ECEobjective = React.lazy(() =>
  import("./components/ece/educationalobjectives")
);
const EceProgramAdvisoryCommittee = React.lazy(() =>
  import("./components/ece/EceProgramAdvisoryCommittee")
);
const ECEoutcomes = React.lazy(() =>
  import("./components/ece/specificoutcomes")
);
const VisionAndMissionece = React.lazy(() =>
  import("./components/ece/VisionAndMissionece")
);
const PeoPsOece = React.lazy(() => import("./components/ece/PeoPsOece"));
const EceSemesterPlanner = React.lazy(() =>
  import("./components/ece/EceSemesterPlanner")
);
const ECEsyllabus = React.lazy(() =>
  import("./components/ece/curriculumsyllabus")
);
const ECEfaculty = React.lazy(() => import("./components/ece/faculties"));
const ECEscholarships = React.lazy(() =>
  import("./components/ece/scholarship")
);
const Eceplacement = React.lazy(() => import("./components/ece/Eceplacement"));
const EceMoUSigned = React.lazy(() => import("./components/ece/EceMoUSigned"));

const EceFeedbackRegarding = React.lazy(() =>
  import("./components/ece/EceFeedbackRegarding")
);
const Architech = React.lazy(() =>
  import("./components/architecture/architecture")
);
const Architechobjective = React.lazy(() =>
  import("./components/architecture/ArchPeoPsO")
);
const Architechoutcomes = React.lazy(() =>
  import("./components/architecture/specificoutcomes")
);
const Architechsyllabus = React.lazy(() =>
  import("./components/architecture/curriculumsyllabus")
);
const Architechfaculty = React.lazy(() =>
  import("./components/architecture/faculties")
);
const ArchPAC = React.lazy(() => import("./components/architecture/ArchPAC"));
const ArchSemesterPlanner = React.lazy(() =>
  import("./components/architecture/ArchSemesterPlanner")
);
const Archplacement = React.lazy(() =>
  import("./components/architecture/Archplacement")
);
const Agritech = React.lazy(() => import("./components/agritech/agritech"));
const Agritechobjective = React.lazy(() =>
  import("./components/agritech/educationalobjectives")
);
const Agritechoutcomes = React.lazy(() =>
  import("./components/agritech/specificoutcomes")
);
const Agritechsyllabus = React.lazy(() =>
  import("./components/agritech/curriculumsyllabus")
);
const Agritechfaculty = React.lazy(() =>
  import("./components/agritech/faculties")
);
const Agritechplacement = React.lazy(() =>
  import("./components/agritech/placement")
);
const Agritechlabfacility = React.lazy(() =>
  import("./components/agritech/labfacility")
);
const Agritechpatents = React.lazy(() =>
  import("./components/agritech/patents")
);
const Agritechrankholders = React.lazy(() =>
  import("./components/agritech/rankholders")
);
const Tamilobjective = React.lazy(() =>
  import("./components/science/tamildept")
);
const Tamilfaculty = React.lazy(() =>
  import("./components/science/tamilfaculty")
);
const Science = React.lazy(() => import("./components/science/science"));
const Scienceobjective = React.lazy(() =>
  import("./components/science/englishdept")
);

const Mathsdept = React.lazy(() => import("./components/science/Mathsdept"));
const Mathsfaculty = React.lazy(() =>
  import("./components/science/Mathsfaculty")
);
const Physicsdept = React.lazy(() =>
  import("./components/science/physicsdept")
);
const Sciencesyllabus = React.lazy(() =>
  import("./components/science/curriculumsyllabus")
);
const Sciencefaculty = React.lazy(() =>
  import("./components/science/Sciencefaculty")
);
const TwentyFourScheme = React.lazy(() =>
  import("./components/science/TwentyFourScheme")
);
const Scienceplacement = React.lazy(() =>
  import("./components/science/placement")
);
const Examination = React.lazy(() => import("./components/Examination"));
const CentreCivilTestingConsultancy = React.lazy(() =>
  import("./components/CentreCivilTestingConsultancy")
);
const TextileTesting = React.lazy(() => import("./components/TextileTesting"));
const Schemecommunity = React.lazy(() =>
  import("./components/Schemecommunity")
);
const Entrepreneurship = React.lazy(() =>
  import("./components/facilities/Entrepreneurship")
);
const Partnershipcell = React.lazy(() =>
  import("./components/Partnershipcell")
);
const Societytechnical = React.lazy(() =>
  import("./components/Societytechnical")
);
const Institutionengineers = React.lazy(() =>
  import("./components/Institutionengineers")
);
const Ncc = React.lazy(() => import("./components/facilities/Ncc"));
const NSS = React.lazy(() => import("./components/facilities/NSS"));
const TamilElakkiyaManram = React.lazy(() =>
  import("./components/facilities/clubs/TamilElakkiyaManram")
);
const Studandclubs = React.lazy(() =>
  import("./components/facilities/Studandclubs")
);
const StudAssoc = React.lazy(() =>
  import("./components/facilities/StudentsAssociation")
);

const Ramanujamclub = React.lazy(() =>
  import("./components/facilities/clubs/Ramanujamclub")
);
const Englishclub = React.lazy(() =>
  import("./components/facilities/clubs/Englishclub")
);
const CVRamanclub = React.lazy(() =>
  import("./components/facilities/clubs/CVRamanclub")
);
const Rotaractclub = React.lazy(() =>
  import("./components/facilities/clubs/Rotaractclub")
);

const Fineartsclub = React.lazy(() =>
  import("./components/facilities/clubs/Fineartsclub")
);
const StudCounselclub = React.lazy(() =>
  import("./components/facilities/clubs/StudCounselclub")
);
const Entrestartupclub = React.lazy(() =>
  import("./components/facilities/clubs/Entrepreneurship-startup")
);
const CleanGreenSmartclub = React.lazy(() =>
  import("./components/facilities/clubs/CleanGreenSmartclub")
);
const HealthYogaclub = React.lazy(() =>
  import("./components/facilities/clubs/HealthYogaclub")
);
const Readersclub = React.lazy(() =>
  import("./components/facilities/clubs/Readersclub")
);
const SpeakersForumclub = React.lazy(() =>
  import("./components/facilities/clubs/SpeakersForumclub")
);
const SportsGamesclub = React.lazy(() =>
  import("./components/facilities/clubs/SportsGamesclub")
);
const Placementclub = React.lazy(() =>
  import("./components/facilities/clubs/Placementclub")
);
const WomenDevelopmentclub = React.lazy(() =>
  import("./components/facilities/clubs/WomenDevelopmentclub")
);
const Library = React.lazy(() => import("./components/Library"));
const Libcollections = React.lazy(() => import("./components/Libcollections"));
const Libdigital = React.lazy(() => import("./components/Libdigital"));
const Libfacilities = React.lazy(() => import("./components/Libfacilities"));
const Libservices = React.lazy(() => import("./components/Libservices"));
const Libstaff = React.lazy(() => import("./components/Libstaff"));
const EminentVisitors = React.lazy(() =>
  import("./components/EminentVisitors")
);
const Scholarship = React.lazy(() => import("./components/Scholarship"));
const Newsletter = React.lazy(() => import("./components/newsletter"));
const Contactpage = React.lazy(() => import("./components/ContactPage"));
const ContinueEdu = React.lazy(() => import("./components/ContinueEdu"));
const ContinueEduOnline = React.lazy(() =>
  import("./components/ContinueEduOnline")
);
const ContinueEduSummer = React.lazy(() =>
  import("./components/ContinueEduSummer")
);
const ContinueEduskillDev = React.lazy(() =>
  import("./components/ContinueEduskillDev")
);
const ContinueEduPopular = React.lazy(() =>
  import("./components/ContinueEduPopular")
);
const ContinueEduFaculty = React.lazy(() =>
  import("./components/ContinueEduFaculty")
);
const ContinueEduContact = React.lazy(() =>
  import("./components/ContinueEduContact")
);
const ContinueEduShorterm = React.lazy(() =>
  import("./components/ContinueEduShorterm")
);
const Sportspage = React.lazy(() => import("./components/Sports"));
// const Sportspage1 = React.lazy(() => import("./components/Sports1"));
const Awardsinglepage = React.lazy(() =>
  import("./components/Awardsinglepage")
);
const EventsViewSinglepage = React.lazy(() =>
  import("./components/EventsViewSinglepage")
);
const CentricLearning = React.lazy(() =>
  import("./components/CentricLearning")
);
const Lms = React.lazy(() => import("./components/Lms"));
const Facilitator = React.lazy(() => import("./components/Facilitator"));
const Employability = React.lazy(() => import("./components/Employability"));
const Techtalk = React.lazy(() => import("./components/Techtalk"));
const EmployerCentric = React.lazy(() =>
  import("./components/EmployerCentric")
);
const Mechlab = React.lazy(() => import("./components/mech/Lab"));
const Mechpatents = React.lazy(() => import("./components/mech/Patents"));
const MechssPublications = React.lazy(() =>
  import("./components/mech/MechssPublications")
);
const MechRankholder = React.lazy(() => import("./components/mech/Rankholder"));
const MechssPAC = React.lazy(() => import("./components/mech/MechssPAC"));
const Mechaided = React.lazy(() => import("./components/mech-aided/mech"));

const MechaidedVisionAndMission = React.lazy(() =>
  import("./components/mech-aided/MechaidedVisionAndMission")
);
const MechaidedPeoPsO = React.lazy(() =>
  import("./components/mech-aided/MechaidedPeoPsO")
);
const MechaidedPatents = React.lazy(() =>
  import("./components/mech-aided/MechaidedPatents")
);
const Mechaidedlab = React.lazy(() => import("./components/mech-aided/Lab"));
const Mechaidedsyllabus = React.lazy(() =>
  import("./components/mech-aided/curriculumsyllabus")
);
const Mechaidedfaculty = React.lazy(() =>
  import("./components/mech-aided/faculties")
);
const Mechaidedplacement = React.lazy(() =>
  import("./components/mech-aided/placement")
);
const Mechaidedpatents = React.lazy(() =>
  import("./components/mech-aided/MechaidedPatents")
);
const MechaidedMoUSigned = React.lazy(() =>
  import("./components/mech-aided/MechaidedMoUSigned")
);
const MechaidedRankholder = React.lazy(() =>
  import("./components/mech-aided/Rankholder")
);
const MechaidedSemesterPlanner = React.lazy(() =>
  import("./components/mech-aided/MechaidedSemesterPlanner")
);
const Mechaidedcoe = React.lazy(() =>
  import("./components/mech-aided/Mechaidedcoe")
);
const Mechaidednews = React.lazy(() =>
  import("./components/mech-aided/Mechaidednews")
);
const Mechpub = React.lazy(() =>
  import("./components/mech-aided/Mechpub")
);
const MechaidedPAC = React.lazy(() =>
  import("./components/mech-aided/MechaidedPAC")
);
const PeLab = React.lazy(() => import("./components/pe/Lab"));
const PEGrants = React.lazy(() => import("./components/pe/Grants"));
const Pepatents = React.lazy(() => import("./components/pe/Patents"));
const Perankholder = React.lazy(() => import("./components/pe/Rankholders"));
const StructuralConsultancy = React.lazy(() =>
  import("./components/StructuralConsultancy")
);
const EceLab = React.lazy(() => import("./components/ece/Lab"));
const Ecerankholder = React.lazy(() => import("./components/ece/Rankholder"));
const ECEGrants = React.lazy(() => import("./components/ece/Grants"));
const ECEPatents = React.lazy(() => import("./components/ece/Patents"));
const EEEPatents = React.lazy(() => import("./components/eee/patents"));
const EEEssplacement = React.lazy(() =>
  import("./components/eee/EEEssplacement")
);
const CSLab = React.lazy(() => import("./components/cs/Lab"));
const CSRankholders = React.lazy(() => import("./components/cs/Rankholders"));
const CSPatents = React.lazy(() => import("./components/cs/Patents"));
const CSGrants = React.lazy(() => import("./components/cs/Grants"));
const CivilLab = React.lazy(() => import("./components/civil/Lab"));
const CivilRankholder = React.lazy(() =>
  import("./components/civil/Rankholder")
);
const CivilPatents = React.lazy(() => import("./components/civil/Patents"));
const CivilPublications = React.lazy(() =>
  import("./components/civil/Publications")
);
const CivilCenterExcellence = React.lazy(() =>
  import("./components/civil/CenterExcellence")
);
const Civilfacultyahcievement = React.lazy(() =>
  import("./components/civil/Ahcievements")
);
const Civilfacultyachivementsingle = React.lazy(() =>
  import("./components/civil/AchievementsSingle")
);
const CivilStdAchievements = React.lazy(() =>
  import("./components/civil/StdAchievements")
);
const CivilStdAchievementssingle = React.lazy(() =>
  import("./components/civil/StdAchievementsSingle")
);
const Agritechfacultyachivementsingle = React.lazy(() =>
  import("./components/agritech/AchievementsSingle")
);
const Agritechfacultyahcievement = React.lazy(() =>
  import("./components/agritech/Ahcievements")
);
const AgritechStdAchievements = React.lazy(() =>
  import("./components/agritech/StdAchievements")
);
const AgritechStdAchievementssingle = React.lazy(() =>
  import("./components/agritech/StdAchievementsSingle")
);
const Architechfacultyahcievement = React.lazy(() =>
  import("./components/architecture/Ahcievements")
);
const Architechfacultyachivementsingle = React.lazy(() =>
  import("./components/architecture/AchievementsSingle")
);
const ArchitechStdAchievements = React.lazy(() =>
  import("./components/architecture/StdAchievements")
);
const ArchitechStdAchievementssingle = React.lazy(() =>
  import("./components/architecture/StdAchievementsSingle")
);
const CSfacultyahcievement = React.lazy(() =>
  import("./components/cs/Ahcievements")
);
const CSfacultyachivementsingle = React.lazy(() =>
  import("./components/cs/AchievementsSingle")
);
const CSStdAchievements = React.lazy(() =>
  import("./components/cs/StdAchievements")
);
const CSStdAchievementssingle = React.lazy(() =>
  import("./components/cs/StdAchievementsSingle")
);
const ECEfacultyahcievement = React.lazy(() =>
  import("./components/ece/Ahcievements")
);
const ECEfacultyachivementsingle = React.lazy(() =>
  import("./components/ece/AchievementsSingle")
);
const ECEStdAchievements = React.lazy(() =>
  import("./components/ece/StdAchievements")
);
const ECEStdAchievementssingle = React.lazy(() =>
  import("./components/ece/StdAchievementsSingle")
);
const EEEfacultyahcievement = React.lazy(() =>
  import("./components/eee/Ahcievements")
);
const EEEfacultyachivementsingle = React.lazy(() =>
  import("./components/eee/AchievementsSingle")
);
const EEEStdAchievements = React.lazy(() =>
  import("./components/eee/StdAchievements")
);
const EEEStdAchievementssingle = React.lazy(() =>
  import("./components/eee/StdAchievementsSingle")
);
const Mechfacultyahcievement = React.lazy(() =>
  import("./components/mech/Ahcievements")
);
const Mechfacultyachivementsingle = React.lazy(() =>
  import("./components/mech/AchievementsSingle")
);
const MechStdAchievements = React.lazy(() =>
  import("./components/mech/StdAchievements")
);
const MechStdAchievementssingle = React.lazy(() =>
  import("./components/mech/StdAchievementsSingle")
);
const MechssMOUSigned = React.lazy(() =>
  import("./components/mech/MechssMOUSigned")
);
const Mechssnews = React.lazy(() => import("./components/mech/Mechssnews"));

const Mechaidedfacultyahcievement = React.lazy(() =>
  import("./components/mech-aided/Ahcievements")
);
const Mechaidedfacultyachivementsingle = React.lazy(() =>
  import("./components/mech-aided/AchievementsSingle")
);
const MechaidedStdAchievements = React.lazy(() =>
  import("./components/mech-aided/StdAchievements")
);
const MechaidedStdAchievementssingle = React.lazy(() =>
  import("./components/mech-aided/StdAchievementsSingle")
);
const PEfacultyahcievement = React.lazy(() =>
  import("./components/pe/Ahcievements")
);
const PEfacultyachivementsingle = React.lazy(() =>
  import("./components/pe/AchievementsSingle")
);
const PEStdAchievements = React.lazy(() =>
  import("./components/pe/StdAchievements")
);
const PEStdAchievementssingle = React.lazy(() =>
  import("./components/pe/StdAchievementsSingle")
);
const Sciencefacultyahcievement = React.lazy(() =>
  import("./components/science/Ahcievements")
);
const Sciencefacultyachivementsingle = React.lazy(() =>
  import("./components/science/AchievementsSingle")
);

const Chemistryfacultyahcievement = React.lazy(() =>
  import("./components/science/chemistryfacultyachievements")
);
const Chemistryfacultyachivementsingle = React.lazy(() =>
  import("./components/science/chemistryfacultyachievementssingle")
);

const Mathsfacultyachivementsingle = React.lazy(() =>
  import("./components/science/Mathsfacultyachievementssingle")
);
const Mathspub = React.lazy(() => import("./components/science/Mathspub"));

const ScienceStdAchievements = React.lazy(() =>
  import("./components/science/StdAchievements")
);
const Chemistrydept = React.lazy(() =>
  import("./components/science/chemistrydept")
);
const ChemistryFaculty = React.lazy(() =>
  import("./components/science/ChemistryFaculty")
);
const ScienceStdAchievementssingle = React.lazy(() =>
  import("./components/science/StdAchievementsSingle")
);
const Textilefacultyahcievement = React.lazy(() =>
  import("./components/textile/Ahcievements")
);
const Textilefacultyachivementsingle = React.lazy(() =>
  import("./components/textile/AchievementsSingle")
);
const TextileStdAchievements = React.lazy(() =>
  import("./components/textile/StdAchievements")
);
const TextileStdAchievementssingle = React.lazy(() =>
  import("./components/textile/StdAchievementsSingle")
);
const Texpatents = React.lazy(() => import("./components/textile/Patentstex"));
const TexRankholders = React.lazy(() =>
  import("./components/textile/Rankholderstex")
);

const Placementpage = React.lazy(() => import("./components/PlacementPage"));
const PlacementContact = React.lazy(() =>
  import("./components/PlacementContact")
);
const PlacementStatistics = React.lazy(() =>
  import("./components/PlacementStatistics")
);
const Admissionbrodure = React.lazy(() =>
  import("./components/Admissionbrodure")
);
const AdmissionCoursesoff = React.lazy(() =>
  import("./components/AdmissionCoursesoff")
);
// const Enquiryform = React.lazy(() => import("./components/Enquiryform"));
const SportsAminties = React.lazy(() => import("./components/SportsAminties"));
const SportsAbout = React.lazy(() => import("./components/SportsAbout"));
const Sportsstaff = React.lazy(() => import("./components/SportsStaff"));
const EEEaided = React.lazy(() => import("./components/eee-aided/eee"));
const EEEaidedVisionAndMission = React.lazy(() =>
  import("./components/eee-aided/EEEaidedVisionAndMission")
);
const EEEaidedPeoPsO = React.lazy(() =>
  import("./components/eee-aided/EEEaidedPeoPsO")
);

const EEEaidedSemPlanner = React.lazy(() =>
  import("./components/eee-aided/SemesterPlanner")
);

const EEEaidedsyllabus = React.lazy(() =>
  import("./components/eee-aided/curriculumsyllabus")
);
const EEEaidedfaculty = React.lazy(() =>
  import("./components/eee-aided/faculties")
);
const EEEaidedfacultyahcievement = React.lazy(() =>
  import("./components/eee-aided/Ahcievements")
);
const EEEaidedfacultyachivementsingle = React.lazy(() =>
  import("./components/eee-aided/AchievementsSingle")
);
const EEEaidedStdAchievements = React.lazy(() =>
  import("./components/eee-aided/StdAchievements")
);
const EEEaidedStdAchievementssingle = React.lazy(() =>
  import("./components/eee-aided/StdAchievementsSingle")
);
const EEEaidedplacement = React.lazy(() =>
  import("./components/eee-aided/placement")
);
const EEEaidedMoUSigned = React.lazy(() =>
  import("./components/eee-aided/EEEaidedMoUSigned")
);
const EEEaidedGrants = React.lazy(() =>
  import("./components/eee-aided/EEEaidedGrants")
);
const EEEaidedPub = React.lazy(() =>
  import("./components/eee-aided/EEEaidedPub")
);
const EEEaidedcoe = React.lazy(() =>
  import("./components/eee-aided/EEEaidedcoe")
);

const EEEaidedLab = React.lazy(() => import("./components/eee-aided/Lab"));
const EEEaidedRankholders = React.lazy(() =>
  import("./components/eee-aided/Rankholders")
);
const EEERankholders = React.lazy(() => import("./components/eee/Rankholders"));
const EEEaidedPatents = React.lazy(() =>
  import("./components/eee-aided/Patents")
);
// const EEEaidedevents = React.lazy(() =>
//   import("./components/eee-aided/EEEaidedevents")
// );
const ArchitechLab = React.lazy(() => import("./components/architecture/Lab"));
const ArchitechRankholders = React.lazy(() =>
  import("./components/architecture/Rankholders")
);
const ArchMoUSigned = React.lazy(() =>
  import("./components/architecture/ArchMoUSigned")
);
const ArchGrants = React.lazy(() =>
  import("./components/architecture/ArchGrants")
);
const ArchNewsletter = React.lazy(() =>
  import("./components/architecture/ArchNewsletter")
);
const ArchVisionandMission = React.lazy(() =>
  import("./components/architecture/ArchVisionandMission")
);
const ArchPeoPsO = React.lazy(() =>
  import("./components/architecture/ArchPeoPsO")
);
const ArchitechPatents = React.lazy(() =>
  import("./components/architecture/Patents")
);
const ArchCOE = React.lazy(() => import("./components/architecture/ArchCOE"));
const AiMl = React.lazy(() => import("./components/aimi/aiml"));

const VisionAndMissionAIML = React.lazy(() =>
  import("./components/aimi/VisionAndMissionAIML")
);
const PeoPsOAIML = React.lazy(() => import("./components/aimi/PeoPsOAIML"));
const AIMLSemesterPlanner = React.lazy(() =>
  import("./components/aimi/AIMLSemesterPlanner")
);

const Counselling = React.lazy(() =>
  import("./components/facilities/Counselling")
);
const TPTAlumniauditorium = React.lazy(() =>
  import("./components/facilities/TPTAlumniauditorium")
);
const SWMS = React.lazy(() =>
  import("./components/facilities/SWMS")
);

const AiMlsyllabus = React.lazy(() =>
  import("./components/aimi/curriculumsyllabus")
);
const AIMLMoUSigned = React.lazy(() =>
  import("./components/aimi/AIMLMoUSigned")
);
const Faculties = React.lazy(() => import("./components/aimi/faculties"));
const AiMlfacultyahcievement = React.lazy(() =>
  import("./components/aimi/Ahcievements")
);
const AiMlfacultyachivementsingle = React.lazy(() =>
  import("./components/aimi/AchievementsSingle")
);
const AiMlStdAchievements = React.lazy(() =>
  import("./components/aimi/StdAchievements")
);
const AiMlStdAchievementssingle = React.lazy(() =>
  import("./components/aimi/StdAchievementsSingle")
);

const AiMlNewsletter = React.lazy(() =>
  import("./components/aimi/NewsLetter")
);

const AiMlPAC = React.lazy(() =>
  import("./components/aimi/ProgramAdvisoryCommittee")
);
const AiMlplacement = React.lazy(() => import("./components/aimi/placement"));
// const AiMlPatents = React.lazy(() => import("./components/aimi/Patents"));
const AiMlRankholders = React.lazy(() =>
  import("./components/aimi/Rankholders")
);

const AiMlLabfacilities = React.lazy(() =>
  import("./components/aimi/Labfacilities")
);

const AiMllabfacility = React.lazy(() => import("./components/aimi/Lab"));
const ExtensionServices = React.lazy(() =>
  import("./components/ExtensionServices")
);
const NewsSinglepage = React.lazy(() => import("./components/NewsSinglepage"));
const Committees = React.lazy(() => import("./components/Committees"));
const Grivancecommittee = React.lazy(() =>
  import("./components/Grivancecommittee")
);
const GRcommitteemember = React.lazy(() =>
  import("./components/GRcommitteemember")
);
const Antiragging = React.lazy(() => import("./components/Anti-ragging"));
const Antisexual = React.lazy(() =>
  import("./components/anti-sexual-harrassment")
);
const Antidrugs = React.lazy(() => import("./components/Anti-drugs"));
const NashaMuktHostel = React.lazy(() =>
  import("./components/NashaMuktHostel")
);
const IQACCommittee = React.lazy(() =>
  import("./components/IQACCommittee")
);
const SCSTCommittee = React.lazy(() =>
  import("./components/SCSTCommittee")
);
const Photocompetition = React.lazy(() =>
  import("./components/Photocompetition")
);
const Mousectioncontent = React.lazy(() =>
  import("./components/Mousectioncontent")
);
// const Submissionlist = React.lazy(() => import('./components/Submissionlist'));
const MechGrants = React.lazy(() => import("./components/mech/Grants"));
const Mechssevents = React.lazy(() => import("./components/mech/Mechssevents"));
const MechAGrants = React.lazy(() => import("./components/mech-aided/Grants"));

const EEEACenterExcellence = React.lazy(() =>
  import("./components/eee-aided/centerexcellence")
);
const EEEaidedNewsletter = React.lazy(() =>
  import("./components/eee-aided/EEEaidedNewsletter")
);
const EEEaidedPAC = React.lazy(() =>
  import("./components/eee-aided/EEEaidedPAC")
);
const Englishiv = React.lazy(() => import("./components/science/englishiv"));
const Engpublications = React.lazy(() => import("./components/science/engpub"));
const Enggrants = React.lazy(() => import("./components/science/enggrants"));
const Engfaculty = React.lazy(() => import("./components/science/Engfaculty"));
const Engfacultyachieve = React.lazy(() =>
  import("./components/science/Englishfacultyachievements")
);

const Englishfacultyachivementsingle = React.lazy(() =>
  import("./components/science/englishfacultyachievementssingle")
);

const Englishstudentachievements = React.lazy(() =>
  import("./components/science/Englishstudentachievements")
);

const Englishstudentsachievementssingle = React.lazy(() =>
  import("./components/science/Englishstudentsachievementssingle")
);

const Physicslab = React.lazy(() => import("./components/science/physicslab"));
const Physicsiv = React.lazy(() => import("./components/science/physicsiv"));
const Physicsfaculty = React.lazy(() => import("./components/science/Physicsfaculty"));
const Physicsstdahcievement = React.lazy(() =>
  import("./components/science/physicsstdachievements")
);

const Mathsstudentachivementsingle = React.lazy(() =>
  import("./components/science/Mathsstudentachivementsingle")
);
const Mathsstudentachievements = React.lazy(() =>
  import("./components/science/Mathsstudentachievements")
);
const Physicsstudentachivementsingledata = React.lazy(() =>
  import("./components/science/Physicsstudentachivementsingle")
);
const Physicspub = React.lazy(() =>
  import("./components/science/Physicspub")
);
const Chemistryfacultyachievements = React.lazy(() =>
  import("./components/science/chemistryfacultyachievements")
);
const Chemistrypub = React.lazy(() =>
  import("./components/science/Chemistrypub")
);
const Chemistryiv = React.lazy(() =>
  import("./components/science/Chemistryiv")
);
const Chemistrylab = React.lazy(() =>
  import("./components/science/Chemistrylab")
);
const Mathsfacultyachievements = React.lazy(() =>
  import("./components/science/Mathsfacultyachievements")
);
const Mathsiv = React.lazy(() => import("./components/science/Mathsiv"));

// const PEsportsachievements = React.lazy(() => import('./components/pe/PESportsachievements'));

const PEsportsachievements = React.lazy(() =>
  import("./components/pe/PEsportsachievements")
);
const PEsinglesportsachievements = React.lazy(() =>
  import("./components/pe/SportsachievementsSingle")
);

const AgriSportsachievements = React.lazy(() =>
  import("./components/agritech/AgriSportsachievements")
);
const Agritechsportssingle = React.lazy(() =>
  import("./components/agritech/AgriSportsachievementsSingle")
);

const EEESportsachievements = React.lazy(() =>
  import("./components/eee/EEESportsachievements")
);
const EEEsinglesportsachievements = React.lazy(() =>
  import("./components/eee/SportsachievementsSingle")
);

const AIMISportsachievements = React.lazy(() =>
  import("./components/aimi/AISportsachievements")
);
const AIMIsinglesportsachievements = React.lazy(() =>
  import("./components/aimi/SportsachievementsSingle")
);

const ArchSportsachievements = React.lazy(() =>
  import("./components/architecture/Archsportsachievements")
);
const Archsinglesportsachievements = React.lazy(() =>
  import("./components/architecture/SportsachievementsSingle")
);

const CivilSportsachievements = React.lazy(() =>
  import("./components/civil/Civilsportsachievements")
);
const Civilsinglesportsachievements = React.lazy(() =>
  import("./components/civil/SportsachievementsSingle")
);

const MechSportsachievements = React.lazy(() =>
  import("./components/mech/Mechsportsachievements")
);
const Mechsinglesportsachievements = React.lazy(() =>
  import("./components/mech/SportsachievementsSingle")
);
const Mechsscoe = React.lazy(() => import("./components/mech/Mechsscoe"));
const Mechssfacultyach = React.lazy(() =>
  import("./components/mech/Mechssfacultyach")
);

const MechaidedSportsachievements = React.lazy(() =>
  import("./components/mech-aided/Mechaidedsportsachievements")
);
const Mechaidedsinglesportsachievements = React.lazy(() =>
  import("./components/mech-aided/SportsachievementsSingle")
);

const EEEaidedSportsachievements = React.lazy(() =>
  import("./components/eee-aided/EEEaidedsportsachievements")
);
const EEEaidedsinglesportsachievements = React.lazy(() =>
  import("./components/eee-aided/SportsachievementsSingle")
);

const CSSportsachievements = React.lazy(() =>
  import("./components/cs/CSsportsachievements")
);

const CSPublications = React.lazy(() =>
  import("./components/cs/CSPublications")
);
const CSsinglesportsachievements = React.lazy(() =>
  import("./components/cs/SportsachievementsSingle")
);

const CSIT = React.lazy(() =>
  import("./components/cs&it/CSIT")
);
const CSITPEOPSO = React.lazy(() =>
  import("./components/cs&it/CSITPEOPSO")
);
const CSITVisionMission = React.lazy(() =>
  import("./components/cs&it/CSITVisionMission")
);

const Texsportsahcievement = React.lazy(() =>
  import("./components/textile/Texsportsachievements")
);
const Texsportsachivementsingle = React.lazy(() =>
  import("./components/textile/SportsachievementsSingle")
);

const ECEsportsahcievement = React.lazy(() =>
  import("./components/ece/ECEsportsachievements")
);
const ECEsportsachivementsingle = React.lazy(() =>
  import("./components/ece/SportsachievementsSingle")
);
const ArchEvents = React.lazy(() =>
  import("./components/architecture/ArchEvents")
);
const Civilevents = React.lazy(() => import("./components/civil/Events"));
const CivilNewsletter = React.lazy(() => import("./components/civil/CivilNewsletter"));
const CSEevents = React.lazy(() => import("./components/cs/events"));
const CSEsports = React.lazy(() => import("./components/cs/sports"));
const CSCOE = React.lazy(() => import("./components/cs/coe"));
const CSnewsletter = React.lazy(() => import("./components/cs/Newsletter"));
const EceNewsletter = React.lazy(() =>
  import("./components/ece/EceNewsletter")
);

const Mechevents = React.lazy(() => import("./components/mech/Events"));
const Peevents = React.lazy(() => import("./components/pe/Events"));
const Eceevents = React.lazy(() => import("./components/ece/Events"));
const Mathsevents = React.lazy(() =>
  import("./components/science/MathsEvents")
);
const Englishevents = React.lazy(() =>
  import("./components/science/englishEvents")
);
const ChemistryEvents = React.lazy(() =>
  import("./components/science/ChemistryEvents")
);
const Physicsevents = React.lazy(() =>
  import("./components/science/physicsEvent")
);
const Eeeevents = React.lazy(() => import("./components/eee/Events"));
const Eeegrants = React.lazy(() => import("./components/eee/EEEgrants"));
const EEEcoe = React.lazy(() => import("./components/eee/EEEcoe"));

const Agrievents = React.lazy(() => import("./components/agritech/Agrievents"));

const Jobopenings = React.lazy(() => import("./components/jobopenings"));

const Studentcorner = React.lazy(() => import("./components/Studentscorner"));
const Eventbymonthlist = React.lazy(() =>
  import("./components/EventsViewMonth")
);
const Aictemandatorydisc = React.lazy(() =>
  import("./components/Aictemandatorydisc")
);

const PressRelease = React.lazy(() =>
  import("./components/PressRelease/press-release")
);

const Careers = React.lazy(() => import("./components/careers/Careers"));
const HelpDesk = React.lazy(() => import("./components/HelpDesk/HelpDesk"));

function App() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Header />
        <div className="App">
          <Routes>
            {/* <Route path="/" element={<Home />} /> */}
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<Aboutpage />} />
            <Route path="/about/history" element={<AboutHistory />}></Route>
            <Route
              path="/administrative-staff"
              element={<AdministrativeStaff />}
            ></Route>
            <Route
              path="/about/vision-mission"
              element={<AboutVisionMission />}
            />
            <Route
              path="/about/sri-karumuttu-thiagarajar-endowment-fund"
              element={<KMEnrollmentFund />}
            />
            <Route path="/about/management" element={<Management />} />
            <Route path="/about/principal" element={<Principalpage />} />
            <Route path="/about/director" element={<DirectorPage />} />
            <Route
              path="/about/principal-message"
              element={<Principalmessage />}
            />
            <Route path="/about/momentous" element={<Momentous />} />
            <Route path="/about/newsletter" element={<Newsletter />} />
            <Route
              path="/about/student-centric-learning"
              element={<CentricLearning />}
            />
            <Route path="/about/lms" element={<Lms />} />
            <Route
              path="/about/student-facilitator"
              element={<Facilitator />}
            />
            <Route path="/about/les" element={<Employability />} />
            <Route path="/about/techtalk" element={<Techtalk />} />
            <Route path="/events/month/:id" element={<Eventbymonthlist />} />
            <Route
              path="/aicte-mandatory-disclosure"
              element={<Aictemandatorydisc />}
            />
            <Route path="/press-release" element={<PressRelease />} />
            <Route
              path="/about/employer-centric-learning-through-skype"
              element={<EmployerCentric />}
            />
            <Route path="/admission" element={<Admissionpage />} />
            <Route
              path="/facilities/counselling-monitoring"
              element={<Counselling />}
            />
             <Route
              path="/facilities/tpt-alumni"
              element={<TPTAlumniauditorium />}
            />
            <Route
              path="/facilities/solid-waste-management-system"
              element={<SWMS />}
            />
            <Route
              path="/admission/procedure"
              element={<AdmissionProcedure />}
            />
            <Route
              path="/admission/courses-offered"
              element={<AdmissionCoursesoff />}
            />
            <Route path="/admission/brochure" element={<Admissionbrodure />} />
            <Route path="/admission/contact" element={<Admissioncontact />} />
            {/* <Route path="/admission/enquriy" element={<Enquiryform />} /> */}
            <Route path="/facilities/hostel" element={<Hostel />}></Route>

            <Route
              path="/department/civil-engineering/about-the-department"
              element={<Civildept />}
            />
            <Route
              path="/department/civil-engineering/vision-and-mission"
              element={<VisionAndMissioncivil />}
            />
            <Route
              path="/department/civil-engineering/peos-pos-psos"
              element={<PeoPsOcivil />}
            />
            <Route
              path="/department/civil-engineering/program-educational-objectives"
              element={<Civilobjective />}
            />
            <Route
              path="/department/civil-engineering/program-specific-outcomes"
              element={<Civiloutcomes />}
            />
            <Route
              path="/department/civil-engineering/program-outcomes"
              element={<Civilprogrammeoutcomes />}
            />
            <Route
              path="/department/civil-engineering/syllabus"
              element={<Civilsyllabus />}
            />
            <Route
              path="/department/civil-engineering/faculty"
              element={<Civilfaculty />}
            />
            <Route
              path="/department/civil-engineering/semester-planner"
              element={<CivilSemesterPlanner />}
            />
            <Route
              path="/department/civil-engineering/faculty-achievements"
              element={<Civilfacultyahcievement />}
            />
            <Route
              path="/department/civil-engineering/faculty-achievements/:id"
              element={<Civilfacultyachivementsingle />}
            />
            <Route
              path="/department/civil-engineering/student-achievements"
              element={<CivilStdAchievements />}
            />
            <Route
              path="/department/civil-engineering/student-achievements/:id"
              element={<CivilStdAchievementssingle />}
            />
            <Route
              path="/department/civil-engineering/program-advisory-committee"
              element={<CivilProgramAdvisoryCommittee />}
            />
            <Route
              path="/department/civil-engineering/placement/"
              element={<Civilplacement />}
            />
            <Route
              path="/department/civil-engineering/patents"
              element={<CivilPatents />}
            />
            <Route
              path="/department/civil-engineering/publications"
              element={<CivilPublications />}
            />
            <Route
              path="/department/civil-engineering/mou-signed"
              element={<MoUSignedcivil />}
            />
            <Route
              path="/department/civil-engineering/centers-of-excellence"
              element={<CivilCenterExcellence />}
            />
            <Route
              path="/department/civil-engineering/lab-facilities"
              element={<CivilLab />}
            />
            <Route
              path="/department/civil-engineering/university-rank-holders"
              element={<CivilRankholder />}
            />
            <Route
              path="/department/civil-engineering/events"
              element={<Civilevents />}
            />
            <Route
              path="/department/civil-engineering/newsletter"
              element={<CivilNewsletter />}
            />
            <Route
              path="/sports/civil-engineering"
              element={<CivilSportsachievements />}
            />
            <Route
              path="/sports/civil-engineering/:id"
              element={<Civilsinglesportsachievements />}
            />
            <Route
              path="/department/mechanical-engineering/about-the-department"
              element={<Mech />}
            />
            <Route
              path="/department/mechanical-engineering/vision-and-mission"
              element={<MechssVisionAndMission />}
            />
            <Route
              path="/department/mechanical-engineering/peos-pos-psos"
              element={<MechssPeoPsO />}
            />

            <Route
              path="/department/mechanical-engineering/lab-facilities"
              element={<Mechlab />}
            />
            <Route
              path="/department/department/mechanical-engineering/syllabus"
              element={<Mechsyllabus />}
            />
            <Route
              path="/department/mechanical-engineering/faculty"
              element={<Mechssfaculty />}
            />
            <Route
              path="/department/mechanical-engineering/placement"
              element={<MechssPlacement />}
            />
            {/* <Route
              path="/department/mechanical-engineering/faculty-achievements"
              element={<Mechfacultyahcievement />}
            />
            <Route
              path="/department/mechanical-engineering/faculty-achievements/:id"
              element={<Mechfacultyachivementsingle />}
            /> */}
            <Route
              path="/department/mechanical-engineering/student-achievements"
              element={<MechStdAchievements />}
            />
            <Route
              path="/department/mechanical-engineering/student-achievements/:id"
              element={<MechStdAchievementssingle />}
            />
            <Route
              path="/department/mechanical-engineering/mou-signed"
              element={<MechssMOUSigned />}
            />
            <Route
              path="/department/mechanical-engineering/patents"
              element={<Mechpatents />}
            />
            <Route
              path="/department/mechanical-engineering/publications"
              element={<MechssPublications />}
            />
            <Route
              path="/department/mechanical-engineering/grants"
              element={<MechGrants />}
            />
             <Route
              path="/department/mechanical-engineering/events"
              element={<Mechssevents />}
            />
            <Route
              path="/department/mechanical-engineering-aided/grants"
              element={<MechAGrants />}
            />
            <Route
              path="/department/mechanical-engineering/events"
              element={<Mechevents />}
            />
            <Route
              path="/sports/mechanical-engineering"
              element={<MechSportsachievements />}
            />
            <Route
              path="/sports/mechanical-engineering/:id"
              element={<Mechsinglesportsachievements />}
            />
            <Route
              path="/department/mechanical-engineering/faculty-achievements"
              element={<Mechssfacultyach />}
            />
            <Route
              path="/department/mechanical-engineering/centers-of-excellence"
              element={<Mechsscoe />}
            />
            <Route
              path="/department/mechanical-engineering/university-rank-holders"
              element={<MechRankholder />}
            />
            <Route
              path="/department/mechanical-engineering/program-advisory-committee"
              element={<MechssPAC />}
            />
            <Route
              path="/department/mechanical-engineering/newsletter"
              element={<Mechssnews />}
            />
             {/* <Route
              path="/department/mechanical-engineering/publications"
              element={<Mechsspub />}
            /> */}
            <Route
              path="/department/mechanical-engineering-aided/about-the-department"
              element={<Mechaided />}
            />
            <Route
              path="/department/mechanical-engineering-aided/vision-and-mission"
              element={<MechaidedVisionAndMission />}
            />
            <Route
              path="/department/mechanical-engineering-aided/peos-pos-psos"
              element={<MechaidedPeoPsO />}
            />
            <Route
              path="/department/mechanical-engineering-aided/lab-facilities"
              element={<Mechaidedlab />}
            />
            <Route
              path="/department/mechanical-engineering-aided/syllabus"
              element={<Mechaidedsyllabus />}
            />
            <Route
              path="/department/mechanical-engineering-aided/faculty"
              element={<Mechaidedfaculty />}
            />
            <Route
              path="/department/mechanical-engineering-aided/faculty-achievements"
              element={<Mechaidedfacultyahcievement />}
            />
            <Route
              path="/department/mechanical-engineering-aided/faculty-achievements/:id"
              element={<Mechaidedfacultyachivementsingle />}
            />
            <Route
              path="/department/mechanical-engineering-aided/student-achievements"
              element={<MechaidedStdAchievements />}
            />
            <Route
              path="/department/mechanical-engineering-aided/student-achievements/:id"
              element={<MechaidedStdAchievementssingle />}
            />
            <Route
              path="/department/mechanical-engineering-aided/semester-planner"
              element={<MechaidedSemesterPlanner />}
            />
            <Route
              path="/department/mechanical-engineering-aided/placement"
              element={<Mechaidedplacement />}
            />
            <Route
              path="/department/mechanical-engineering-aided/patents"
              element={<Mechaidedpatents />}
            />
            <Route
              path="/department/mechanical-engineering-aided/mou-signed"
              element={<MechaidedMoUSigned />}
            />
            <Route
              path="/department/mechanical-engineering-aided/university-rank-holders"
              element={<MechaidedRankholder />}
            />
            <Route
              path="/department/mechanical-engineering-aided/centers-of-excellence"
              element={<Mechaidedcoe />}
            />
            <Route
              path="/department/mechanical-engineering-aided/newsletter"
              element={<Mechaidednews />}
            />
             <Route
              path="/department/mechanical-engineering-aided/publications"
              element={<Mechpub />}
            />
            <Route
              path="/department/mechanical-engineering-aided/program-advisory-committee"
              element={<MechaidedPAC />}
            />
            <Route
              path="/sports/mechanical-engineering-aided"
              element={<MechaidedSportsachievements />}
            />
            <Route
              path="/sports/mechanical-engineering-aided/:id"
              element={<Mechaidedsinglesportsachievements />}
            />
            <Route
              path="/department/electrical-electronics-engineering/about-the-department"
              element={<EEE />}
            />
            <Route
              path="/department/electrical-electronics-engineering/vision-and-mission"
              element={<EEEVisionAndMission />}
            />
            <Route
              path="/department/electrical-electronics-engineering/semester-planner"
              element={<EEESemesterPlanner />}
            />

            <Route
              path="/department/electrical-electronics-engineering/peos-pos-psos"
              element={<EEEPeoPsO />}
            />
            <Route
              path="/department/electrical-electronics-engineering/syllabus"
              element={<EEEsyllabus />}
            />
            <Route
              path="/department/electrical-electronics-engineering/faculty"
              element={<EEEfaculty />}
            />
            <Route
              path="/department/electrical-electronics-engineering/lab-facilities"
              element={<EEElab />}
            />
            <Route
              path="/department/electrical-electronics-engineering/faculty-achievements"
              element={<EEEfacultyahcievement />}
            />
            <Route
              path="/department/electrical-electronics-engineering/faculty-achievements/:id"
              element={<EEEfacultyachivementsingle />}
            />
            <Route
              path="/department/electrical-electronics-engineering/student-achievements"
              element={<EEEStdAchievements />}
            />
            <Route
              path="/department/electrical-electronics-engineering/student-achievements/:id"
              element={<EEEStdAchievementssingle />}
            />
            <Route
              path="/department/electrical-electronics-engineering/placement"
              element={<EEEssplacement />}
            />
            <Route
              path="/department/electrical-electronics-engineering/patents"
              element={<EEEPatents />}
            />
            <Route
              path="/department/electrical-electronics-engineering/university-rank-holders"
              element={<EEERankholders />}
            />
            <Route
              path="/department/electrical-electronics-engineering/events"
              element={<Eeeevents />}
            />
            <Route
              path="/department/electrical-electronics-engineering/grants"
              element={<Eeegrants />}
            />
            <Route
              path="/department/electrical-electronics-engineering/centers-of-excellence"
              element={<EEEcoe />}
            />
            <Route
              path="/sports/electrical-electronics-engineering/"
              element={<EEESportsachievements />}
            />
            <Route
              path="/sports/electrical-electronics-engineering/:id"
              element={<EEEsinglesportsachievements />}
            />
            {/* <Route path="/electrical-electronics-engineering/sports-achievement/:id" element={<EEEsinglesportsachievements />} /> */}
            <Route
              path="/department/electrical-electronics-engineering-aided/about-the-department"
              element={<EEEaided />}
            />
            <Route
              path="/department/electrical-electronics-engineering-aided/vision-and-mission"
              element={<EEEaidedVisionAndMission />}
            />
            <Route
              path="/department/electrical-electronics-engineering-aided/peos-pos-psos"
              element={<EEEaidedPeoPsO />}
            />
            <Route
              path="/department/electrical-electronics-engineering-aided/semester-planner"
              element={<EEEaidedSemPlanner />}
            />

            <Route
              path="/department/electrical-electronics-engineering-aided/syllabus"
              element={<EEEaidedsyllabus />}
            />
            <Route
              path="/department/electrical-electronics-engineering-aided/faculty"
              element={<EEEaidedfaculty />}
            />
            <Route
              path="/department/electrical-electronics-engineering-aided/faculty-achievements"
              element={<EEEaidedfacultyahcievement />}
            />
            <Route
              path="/department/electrical-electronics-engineering-aided/faculty-achievements/:id"
              element={<EEEaidedfacultyachivementsingle />}
            />
            <Route
              path="/department/electrical-electronics-engineering-aided/student-achievements"
              element={<EEEaidedStdAchievements />}
            />
            <Route
              path="/department/electrical-electronics-engineering-aided/student-achievements/:id"
              element={<EEEaidedStdAchievementssingle />}
            />
            <Route
              path="/department/electrical-electronics-engineering-aided/placement"
              element={<EEEaidedplacement />}
            />
            <Route
              path="/department/electrical-electronics-engineering-aided/mou-signed"
              element={<EEEaidedMoUSigned />}
            />
            <Route
              path="/department/electrical-electronics-engineering-aided/grants"
              element={<EEEaidedGrants />}
            />
            <Route
              path="/department/electrical-electronics-engineering-aided/publications"
              element={<EEEaidedPub />}
            />
            <Route
              path="/department/electrical-electronics-engineering-aided/centers-of-excellence"
              element={<EEEaidedcoe />}
            />
            <Route
              path="/department/electrical-electronics-engineering-aided/lab-facilities"
              element={<EEEaidedLab />}
            />
            <Route
              path="/department/electrical-electronics-engineering-aided/university-rank-holders"
              element={<EEEaidedRankholders />}
            />
            <Route
              path="/department/electrical-electronics-engineering-aided/patents"
              element={<EEEaidedPatents />}
            />

            <Route
              path="/department/electrical-electronics-engineering-aided/coe"
              element={<EEEACenterExcellence />}
            />
            <Route
              path="/department/electrical-electronics-engineering-aided/newsletter"
              element={<EEEaidedNewsletter />}
            />
            <Route
              path="/department/electrical-electronics-engineering-aided/program-advisory-committee"
              element={<EEEaidedPAC />}
            />
            <Route
              path="/sports/electrical-electronics-engineering-aided/"
              element={<EEEaidedSportsachievements />}
            />
            <Route
              path="/sports/electrical-electronics-engineering-aided/:id"
              element={<EEEaidedsinglesportsachievements />}
            />
            <Route
              path="/department/production-engineering/about-the-department"
              element={<PE />}
            />
            <Route
              path="/department/production-engineering/vision-and-mission"
              element={<PeVisionAndMission />}
            />
            <Route
              path="/department/production-engineering/semester-planner"
              element={<PESemesterPlanner />}
            />
            <Route
              path="/department/production-engineering/publications"
              element={<PePublications />}
            />
            <Route
              path="/department/production-engineering/newsletter"
              element={<PeNewsLetter />}
            />
            <Route
              path="/department/production-engineering/peos-pos-psos"
              element={<PeoPsOPE />}
            />
            <Route
              path="/department/production-engineering/mou-signed"
              element={<PeMoUSigned />}
            />
            <Route
              path="/department/production-engineering/program-advisory-committee"
              element={<PEPAC />}
            />

            <Route
              path="/department/production-engineering/faculty"
              element={<PEfaculty />}
            />
            <Route
              path="/department/production-engineering/centers-of-excellence"
              element={<PECOE />}
            />
            <Route
              path="/department/production-engineering/faculty-achievements"
              element={<PEfacultyahcievement />}
            />
            <Route
              path="/department/production-engineering/faculty-achievements/:id"
              element={<PEfacultyachivementsingle />}
            />
            <Route
              path="/department/production-engineering/student-achievements"
              element={<PEStdAchievements />}
            />
            <Route
              path="/department/production-engineering/student-achievements/:id"
              element={<PEStdAchievementssingle />}
            />
            <Route
              path="/department/production-engineering/placement"
              element={<PEplacement />}
            />
            <Route
              path="/department/production-engineering/patents"
              element={<Pepatents />}
            />
            <Route
              path="/department/production-engineering/grants"
              element={<PEGrants />}
            />
            <Route
              path="/department/production-engineering/university-rank-holders"
              element={<Perankholder />}
            />
            <Route
              path="/department/production-engineering/lab-facilities"
              element={<PeLab />}
            />
            <Route
              path="/department/production-engineering/events"
              element={<Peevents />}
            />
            <Route
              path="/sports/production-engineering"
              element={<PEsportsachievements />}
            />
            <Route
              path="/sports/production-engineering/:id"
              element={<PEsinglesportsachievements />}
            />
            {/* <Route path="/electrical-electronics-engineering/sports-achievements" element={<EEESportsachievements />} /> */}
            {/* <Route path="/production-engineering/sports-achievements" element={<PEsportsachievements />} /> */}
            <Route
              path="/department/textile-technology/about-the-department"
              element={<Textile />}
            />
            <Route
              path="/department/textile-technology/vision-and-mission"
              element={<VisionandMissiontex />}
            />
            <Route
              path="/department/textile-technology/peos-pos-psos"
              element={<PeoPsotex />}
            />
            <Route
              path="/department/textile-technology/syllabus"
              element={<Textilesyllabus />}
            />
            <Route
              path="/department/textile-technology/program-advisory-committee"
              element={<TextilePAC />}
            />
            <Route
              path="/department/textile-technology/faculty"
              element={<Textilefaculty />}
            />
            <Route
              path="/department/textile-technology/placement"
              element={<TexPlacement />}
            />
            <Route
              path="/department/textile-technology/faculty-achievements"
              element={<Textilefacultyahcievement />}
            />
            <Route
              path="/department/textile-technology/faculty-achievements/:id"
              element={<Textilefacultyachivementsingle />}
            />
            <Route
              path="/department/textile-technology/student-achievements"
              element={<TextileStdAchievements />}
            />
            <Route
              path="/department/textile-technology/mou-signed"
              element={<MoUSignedtex />}
            />
             <Route
              path="/department/textile-technology/publications"
              element={<Texpub />}
            />
            <Route
              path="/department/textile-technology/lab-facilities"
              element={<Texlab />}
            />
            <Route
              path="/department/textile-technology/newsletter"
              element={<TexNewsletter />}
            />
             {/* <Route
              path="/department/textile-technology/centers-of-excellence"
              element={<Texcoe />}
            /> */}
            <Route
              path="/department/textile-technology/patents"
              element={<Texpatents />}
            />
            <Route
              path="/department/textile-technology/student-achievements/:id"
              element={<TextileStdAchievementssingle />}
            />
            <Route
              path="/department/textile-technology/placement"
              element={<Textileplacement />}
            />
            <Route
              path="/department/textile-technology/university-rank-holders"
              element={<TexRankholders />}
            />
            <Route
              path="/sports/textile-technology"
              element={<Texsportsahcievement />}
            />
            <Route
              path="/sports/textile-technology/:id"
              element={<Texsportsachivementsingle />}
            />
            <Route
              path="/department/computer-engineering/about-the-department"
              element={<CS />}
            />
            <Route
              path="/department/computer-engineering/program-educational-objectives"
              element={<CSobjective />}
            />
            <Route
              path="/department/computer-engineering/program-specific-outcomes"
              element={<CSoutcomes />}
            />
            <Route
              path="/department/computer-engineering/syllabus"
              element={<CSsyllabus />}
            />
            <Route
              path="/department/computer-engineering/faculty"
              element={<CSfaculty />}
            />
            <Route
              path="/department/computer-engineering/semester-planner"
              element={<CSSemesterPlanner />}
            />
            <Route
              path="/department/computer-engineering/placement"
              element={<CSplacement />}
            />
            <Route
              path="/department/computer-engineering/program-advisory-committee"
              element={<CSProgramAdvisoryCommittee />}
            />
            <Route
              path="/examination/academic-calendar"
              element={<CSAcademicCalendar />}
            />
            <Route
              path="/department/computer-engineering/vision-and-mission"
              element={<VisionAndMission />}
            />
            <Route
              path="/department/computer-engineering/feedback-regarding"
              element={<FeedbackRegarding />}
            />
            <Route
              path="/department/computer-engineering/peos-pos-psos"
              element={<PeoPsO />}
            />
            <Route
              path="/department/computer-engineering/mou-signed"
              element={<CsMoUSigned />}
            />
            <Route
              path="/department/computer-engineering/newsletter"
              element={<CSnewsletter />}
            />
            <Route
              path="/department/computer-engineering/faculty-achievements"
              element={<CSfacultyahcievement />}
            />
            <Route
              path="/department/computer-engineering/faculty-achievements/:id"
              element={<CSfacultyachivementsingle />}
            />
            <Route
              path="/department/computer-engineering/student-achievements"
              element={<CSStdAchievements />}
            />
            <Route
              path="/department/computer-engineering/student-achievements/:id"
              element={<CSStdAchievementssingle />}
            />
            <Route
              path="/department/computer-engineering/lab-facilities"
              element={<CSLab />}
            />
            <Route
              path="/department/computer-engineering/events"
              element={<CSEevents />}
            />
            <Route
              path="department//computer-engineering/sports"
              element={<CSEsports />}
            />
            <Route
              path="/department/computer-engineering/centers-of-excellence"
              element={<CSCOE />}
            />
            <Route
              path="/department/computer-engineering/university-rank-holders"
              element={<CSRankholders />}
            />
            <Route
              path="/department/computer-engineering/patents"
              element={<CSPatents />}
            />
            <Route
              path="/department/computer-engineering/grants"
              element={<CSGrants />}
            />
            <Route
              path="/sports/computer-engineering"
              element={<CSSportsachievements />}
            />
            <Route
              path="/department/computer-engineering/publications"
              element={<CSPublications />}
            />
            <Route
              path="/sports/computer-engineering/:id"
              element={<CSsinglesportsachievements />}
            />




<Route
              path="/department/computer-science-information-technology/about-the-department"
              element={<CSIT />}
            />
            <Route
              path="/department/computer-science-information-technology/peos-pos-psos"
              element={<CSITPEOPSO />}
            />
           
            {/* <Route
              path="/department/computer-science-information-technology/syllabus"
              element={<CSsyllabus />}
            />
            <Route
              path="/department/computer-science-information-technology/faculty"
              element={<CSfaculty />}
            />
            <Route
              path="/department/computer-science-information-technology/semester-planner"
              element={<CSSemesterPlanner />}
            />
            <Route
              path="/department/computer-science-information-technology/placement"
              element={<CSplacement />}
            />
            <Route
              path="/department/computer-science-information-technology/program-advisory-committee"
              element={<CSProgramAdvisoryCommittee />}
            />
            <Route
              path="/examination/academic-calendar"
              element={<CSAcademicCalendar />}
            /> */}
            <Route
              path="/department/computer-science-information-technology/vision-and-mission"
              element={<CSITVisionMission />}
            />
            {/* <Route
              path="/department/computer-science-information-technology/feedback-regarding"
              element={<FeedbackRegarding />}
            />
            <Route
              path="/department/computer-science-information-technology/peos-pos-psos"
              element={<PeoPsO />}
            />
            <Route
              path="/department/computer-science-information-technology/mou-signed"
              element={<CsMoUSigned />}
            />
            <Route
              path="/department/computer-science-information-technology/newsletter"
              element={<CSnewsletter />}
            />
            <Route
              path="/department/computer-science-information-technology/faculty-achievements"
              element={<CSfacultyahcievement />}
            />
            <Route
              path="/department/computer-science-information-technology/faculty-achievements/:id"
              element={<CSfacultyachivementsingle />}
            />
            <Route
              path="/department/computer-science-information-technology/student-achievements"
              element={<CSStdAchievements />}
            />
            <Route
              path="/department/computer-science-information-technology/student-achievements/:id"
              element={<CSStdAchievementssingle />}
            />
            <Route
              path="/department/computer-science-information-technology/lab-facilities"
              element={<CSLab />}
            />
            <Route
              path="/department/computer-science-information-technology/events"
              element={<CSEevents />}
            />
            <Route
              path="department//computer-science-information-technology/sports"
              element={<CSEsports />}
            />
            <Route
              path="/department/computer-science-information-technology/centers-of-excellence"
              element={<CSCOE />}
            />
            <Route
              path="/department/computer-science-information-technology/university-rank-holders"
              element={<CSRankholders />}
            />
            <Route
              path="/department/computer-science-information-technology/patents"
              element={<CSPatents />}
            />
            <Route
              path="/department/computer-science-information-technology/grants"
              element={<CSGrants />}
            />
            <Route
              path="/sports/computer-science-information-technology"
              element={<CSSportsachievements />}
            />
            <Route
              path="/department/computer-science-information-technology/publications"
              element={<CSPublications />}
            />
            <Route
              path="/sports/computer-science-information-technology/:id"
              element={<CSsinglesportsachievements />}
            />

 */}

            <Route
              path="/department/electronics-communication-engineering/about-the-department"
              element={<ECE />}
            />
            <Route
              path="/department/electronics-communication-engineering/vision-and-mission"
              element={<VisionAndMissionece />}
            />
            <Route
              path="/department/electronics-communication-engineering/peos-pos-psos"
              element={<PeoPsOece />}
            />
            <Route
              path="/department/electronics-communication-engineering/semester-planner"
              element={<EceSemesterPlanner />}
            />
            <Route
              path="/department/electronics-communication-engineering/program-educational-objectives"
              element={<ECEobjective />}
            />
            <Route
              path="/department/electronics-communication-engineering/program-advisory-committee"
              element={<EceProgramAdvisoryCommittee />}
            />
            <Route
              path="/department/electronics-communication-engineering/program-specific-outcomes"
              element={<ECEoutcomes />}
            />
            <Route
              path="/department/electronics-communication-engineering/mou-signed"
              element={<EceMoUSigned />}
            />
            <Route
              path="/department/electronics-communication-engineering/syllabus"
              element={<ECEsyllabus />}
            />
            <Route
              path="/department/electronics-communication-engineering/faculty"
              element={<ECEfaculty />}
            />
            <Route
              path="/department/electronics-communication-engineering/scholarships"
              element={<ECEscholarships />}
            />
            <Route
              path="/department/electronics-communication-engineering/faculty-achievements"
              element={<ECEfacultyahcievement />}
            />
            <Route
              path="/department/electronics-communication-engineering/faculty-achievements/:id"
              element={<ECEfacultyachivementsingle />}
            />
            <Route
              path="/department/electronics-communication-engineering/student-achievements"
              element={<ECEStdAchievements />}
            />
            <Route
              path="/department/electronics-communication-engineering/student-achievements/:id"
              element={<ECEStdAchievementssingle />}
            />
            <Route
              path="/department/electronics-communication-engineering/Eceplacement"
              element={<Eceplacement />}
            />
            <Route
              path="/department/electronics-communication-engineering/grants"
              element={<ECEGrants />}
            />
            <Route
              path="/department/electronics-communication-engineering/patents"
              element={<ECEPatents />}
            />
            <Route
              path="/department/electronics-communication-engineering/lab-facilities"
              element={<EceLab />}
            />
            <Route
              path="/department/electronics-communication-engineering/university-rank-holders"
              element={<Ecerankholder />}
            />
            <Route
              path="/department/electronics-communication-engineering/events"
              element={<Eceevents />}
            />
            <Route
              path="/department/electronics-communication-engineering/feedback-regarding"
              element={<EceFeedbackRegarding />}
            />
            <Route
              path="/department/electronics-communication-engineering/newsletter"
              element={<EceNewsletter />}
            />
            <Route
              path="/sports/electronics-communication-engineering"
              element={<ECEsportsahcievement />}
            />
            <Route
              path="/sports/electronics-communication-engineering/:id"
              element={<ECEsportsachivementsingle />}
            />
            <Route
              path="/department/architectural-engineering/about-the-department"
              element={<Architech />}
            />
            <Route
              path="/department/architectural-engineering/vision-and-mission"
              element={<ArchVisionandMission />}
            />
            <Route
              path="/department/architectural-engineering/peos-pos-psos"
              element={<ArchPeoPsO />}
            />
            <Route
              path="/department/architectural-engineering/syllabus"
              element={<Architechsyllabus />}
            />
            <Route
              path="/department/architectural-engineering/faculty"
              element={<Architechfaculty />}
            />
            <Route
              path="/department/architectural-engineering/program-advisory-committee"
              element={<ArchPAC />}
            />
            <Route
              path="/department/architectural-engineering/semester-planner"
              element={<ArchSemesterPlanner />}
            />
            <Route
              path="/department/architectural-engineering/faculty-achievements"
              element={<Architechfacultyahcievement />}
            />
            <Route
              path="/department/architectural-engineering/faculty-achievements/:id"
              element={<Architechfacultyachivementsingle />}
            />
            <Route
              path="/department/architectural-engineering/events"
              element={<ArchEvents />}
            />
            <Route
              path="/sports/architectural-engineering"
              element={<ArchSportsachievements />}
            />
            <Route
              path="/sports/architectural-engineering/:id"
              element={<Archsinglesportsachievements />}
            />
            <Route
              path="/department/architectural-engineering/student-achievements"
              element={<ArchitechStdAchievements />}
            />
            <Route
              path="/department/architectural-engineering/student-achievements/:id"
              element={<ArchitechStdAchievementssingle />}
            />
            <Route
              path="/department/architectural-engineering/placement"
              element={<Archplacement />}
            />
            <Route
              path="/department/architectural-engineering/lab-facilities"
              element={<ArchitechLab />}
            />
            <Route
              path="/department/architectural-engineering/university-rank-holders"
              element={<ArchitechRankholders />}
            />
            <Route
              path="/department/architectural-engineering/mou-signed"
              element={<ArchMoUSigned />}
            />
            <Route
              path="/department/architectural-engineering/grants"
              element={<ArchGrants />}
            />
            <Route
              path="/department/architectural-engineering/newsletter"
              element={<ArchNewsletter />}
            />
            <Route
              path="/department/architectural-engineering/patents"
              element={<ArchitechPatents />}
            />
            <Route
              path="/department/architectural-engineering/centers-of-excellence"
              element={<ArchCOE />}
            />
            <Route
              path="/department/agriculutural-technology"
              element={<Agritech />}
            />
            <Route
              path="/department/agriculutural-technology/program-educational-objectives"
              element={<Agritechobjective />}
            />
            <Route
              path="/department/agriculutural-technology/program-specific-outcomes"
              element={<Agritechoutcomes />}
            />
            <Route
              path="/department/agriculutural-technology/syllabus"
              element={<Agritechsyllabus />}
            />
            <Route
              path="/department/agriculutural-technology/faculty"
              element={<Agritechfaculty />}
            />
            <Route
              path="/department/agriculutural-technology/faculty-achievements"
              element={<Agritechfacultyahcievement />}
            />
            <Route
              path="/department/agriculutural-technology/faculty-achievements/:id"
              element={<Agritechfacultyachivementsingle />}
            />
            <Route
              path="/department/agriculutural-technology/student-achievements"
              element={<AgritechStdAchievements />}
            />
            <Route
              path="/department/agriculutural-technology/student-achievements/:id"
              element={<AgritechStdAchievementssingle />}
            />
            <Route
              path="/department/agriculutural-technology/placement"
              element={<Agritechplacement />}
            />
            <Route
              path="/department/agriculutural-technology/placement/:id"
              element={<Agritechplacement />}
            />
            <Route
              path="/department/agriculutural-technology/labfacility"
              element={<Agritechlabfacility />}
            />
            <Route
              path="/department/agriculutural-technology/patents"
              element={<Agritechpatents />}
            />
            <Route
              path="/department/agriculutural-technology/university-rank-holders"
              element={<Agritechrankholders />}
            />
            <Route
              path="/department/agriculutural-technology/university-rank-holders"
              element={<Agritechrankholders />}
            />
            <Route
              path="/department/agriculutural-technology/events"
              element={<Agrievents />}
            />
            <Route
              path="/sports/agriculutural-technology"
              element={<AgriSportsachievements />}
            />
            <Route
              path="/sports/agriculutural-technology/:id"
              element={<Agritechsportssingle />}
            />
            <Route
              path="/department/science-humanities/about-the-department"
              element={<Science />}
            />
            <Route
              path="/department/science-humanities/tamil"
              element={<Tamilobjective />}
            />
            <Route
              path="/department/science-humanities/tamil/faculty"
              element={<Tamilfaculty />}
            />
            <Route
              path="/department/science-humanities/english"
              element={<Scienceobjective />}
            />
            <Route
              path="/department/science-humanities/english/iv"
              element={<Englishiv />}
            />
            <Route
              path="/department/science-humanities/english/publications"
              element={<Engpublications />}
            />
            <Route
              path="/department/science-humanities/english/grants"
              element={<Enggrants />}
            />
            <Route
              path="/department/science-humanities/english/faculty"
              element={<Engfaculty />}
            />
            <Route
              path="/department/science-humanities/english/facultyachievements"
              element={<Engfacultyachieve />}
            />
       

            <Route
              path="/department/science-humanities/english/faculty-achievements/:id"
              element={<Englishfacultyachivementsingle />}
            />
                 <Route
              path="/department/science-humanities/english/studentachievements"
              element={<Englishstudentachievements />}
            />
            <Route
              path="/department/science-humanities/english/student-achievements/:id"
              element={<Englishstudentsachievementssingle />}
            />
            <Route
              path="/department/science-humanities/english/events"
              element={<Englishevents />}
            />
            <Route
              path="/department/science-humanities/maths"
              element={<Mathsdept />}
            />
            <Route
              path="/department/science-humanities/maths/faculty"
              element={<Mathsfaculty />}
            />
            <Route
              path="/department/science-humanities/maths/iv"
              element={<Mathsiv />}
            />
            <Route
              path="/department/science-humanities/maths/student-achievements"
              element={<Mathsstudentachievements />}
            />
            {/* <Route
              path="/department/science-humanities/maths/student-achievements/:id"
              element={<Mathsstudentachivementsingle />}
            /> */}
            <Route
              path="/department/science-humanities/maths/faculty-achievements"
              element={<Mathsfacultyachievements />}
            />
            <Route
              path="/department/science-humanities/maths/faculty-achievements/:id"
              element={<Mathsfacultyachivementsingle />}
            />
            <Route
              path="/department/science-humanities/maths/publications"
              element={<Mathspub />}
            />
            <Route
              path="/department/science-humanities/maths/events"
              element={<Mathsevents />}
            />
            <Route
              path="/department/science-humanities/physics/events"
              element={<Physicsevents />}
            />
            <Route
              path="/department/science-humanities/physics"
              element={<Physicsdept />}
            />
            <Route
              path="/department/science-humanities/physics/lab"
              element={<Physicslab />}
            />
            <Route
              path="/department/science-humanities/physics/faculty"
              element={<Physicsfaculty />}
            />
            <Route
              path="/department/science-humanities/physics/iv"
              element={<Physicsiv />}
            />
            <Route
              path="/department/science-humanities/physics/student-achievements"
              element={<Physicsstdahcievement />}
            />
            <Route
              path="/department/science-humanities/physics/student-achievements/:id"
              element={<Physicsstudentachivementsingledata />}
            />
             <Route
              path="/department/science-humanities/physics/publications"
              element={<Physicspub />}
            />
            <Route
              path="/department/science-humanities/chemistry"
              element={<Chemistrydept />}
            />
            <Route
              path="/department/science-humanities/chemistry/faculty"
              element={<ChemistryFaculty />}
            />
            <Route
              path="/department/science-humanities/chemistry/lab"
              element={<Chemistrylab />}
            />
            <Route
              path="/department/science-humanities/chemistry/iv"
              element={<Chemistryiv />}
            />
            <Route
              path="/department/science-humanities/chemistry/facultyachievements"
              element={<Chemistryfacultyachievements />}
            />
            <Route
              path="/department/science-humanities/chemistry/publications"
              element={<Chemistrypub />}
            />
            <Route
              path="/department/science-humanities/syllabus"
              element={<Sciencesyllabus />}
            />
            <Route
              path="/department/science-humanities/faculties"
              element={<Sciencefaculty />}
            />
            <Route
              path="/department/science-humanities/twenty-four-o-scheme"
              element={<TwentyFourScheme />}
            />
            <Route
              path="/department/science-humanities/chemistry/faculty-achievements"
              element={<Chemistryfacultyahcievement />}
            />
            <Route
              path="/department/science-humanities/chemistry/faculty-achievements/:id"
              element={<Chemistryfacultyachivementsingle />}
            />
            <Route
              path="/department/science-humanities/chemistry/events"
              element={<ChemistryEvents />}
            />
            <Route
              path="/department/science-humanities/faculty-achievements"
              element={<Sciencefacultyahcievement />}
            />
            <Route
              path="/department/science-humanities/faculty-achievements/:id"
              element={<Sciencefacultyachivementsingle />}
            />
            <Route
              path="/department/science-humanities/student-achievements"
              element={<ScienceStdAchievements />}
            />
            <Route
              path="/department/science-humanities/student-achievements/:id"
              element={<ScienceStdAchievementssingle />}
            />
            <Route
              path="/department/science-humanities/placement"
              element={<Scienceplacement />}
            />
            <Route
              path="/department/artificial-intelligence-machine-learning/about-the-department"
              element={<AiMl />}
            />
            <Route
              path="/department/artificial-intelligence-machine-learning/vision-and-mission"
              element={<VisionAndMissionAIML />}
            />
            <Route
              path="/department/artificial-intelligence-machine-learning/peos-pos-psos"
              element={<PeoPsOAIML />}
            />
            <Route
              path="/department/artificial-intelligence-machine-learning/semester-planner"
              element={<AIMLSemesterPlanner />}
            />
            <Route
              path="/department/artificial-intelligence-machine-learning/syllabus"
              element={<AiMlsyllabus />}
            />
            <Route
              path="/department/artificial-intelligence-machine-learning/faculty"
              element={<Faculties />}
            />
            <Route
              path="/department/artificial-intelligence-machine-learning/mou-signed"
              element={<AIMLMoUSigned />}
            />
            <Route
              path="/department/artificial-intelligence-machine-learning/faculty-achievements"
              element={<AiMlfacultyahcievement />}
            />
            <Route
              path="/department/artificial-intelligence-machine-learning/faculty-achievements/:id"
              element={<AiMlfacultyachivementsingle />}
            />
            <Route
              path="/department/artificial-intelligence-machine-learning/student-achievements"
              element={<AiMlStdAchievements />}
            />
            <Route
              path="/department/artificial-intelligence-machine-learning/student-achievements/:id"
              element={<AiMlStdAchievementssingle />}
            />
            <Route
              path="/department/artificial-intelligence-machine-learning/newsletter"
              element={<AiMlNewsletter />}
            />
            <Route
              path="/department/artificial-intelligence-machine-learning/program-advisory-comittee"
              element={<AiMlPAC />}
            />
            <Route
              path="/department/artificial-intelligence-machine-learning/placement"
              element={<AiMlplacement />}
            />
            <Route
              path="/department/artificial-intelligence-machine-learning/placement/:id"
              element={<AiMlplacement />}
            />
            <Route
              path="/department/artificial-intelligence-machine-learning/labfacility"
              element={<AiMllabfacility />}
            />
            {/* <Route
              path="/department/artificial-intelligence-machine-learning/patents"
              element={<AiMlPatents />}
            /> */}
            <Route
              path="/sports/artificial-intelligence-machine-learning"
              element={<AIMISportsachievements />}
            />
            <Route
              path="/sports/artificial-intelligence-machine-learning/:id"
              element={<AIMIsinglesportsachievements />}
            />
            <Route
              path="/department/artificial-intelligence-machine-learning/university-rank-holders"
              element={<AiMlRankholders />}
            />
            <Route
              path="/department/artificial-intelligence-machine-learning/lab-facilities"
              element={<AiMlLabfacilities />}
            />
            <Route path="/examination" element={<Examination />} />
            <Route path="/examination/results" element={<Results />} />
            <Route path="/examination" element={<Examination />} />
            <Route
              path="/examination/students-corner"
              element={<Studentcorner />}
            />
            <Route
              path="/examination/scheme-rules-regulations"
              element={<ExaminationRules />}
            />
            <Route
              path="/examination/governing-bodies"
              element={<ExaminationGoveringbody />}
            />
            <Route
              path="/examination/contact"
              element={<ExaminationContact />}
            />
            <Route
              path="/examination/curricula-and-syllabi"
              element={<CurriculaAndSyllabi />}
            />
            <Route path="/extension-services" element={<ExtensionServices />} />
            <Route
              path="/extension-services/centre-for-civil-testing-and-consultancy"
              element={<CentreCivilTestingConsultancy />}
            />
            <Route
              path="/extension-services/center-structural-consultancy"
              element={<StructuralConsultancy />}
            />
            <Route
              path="/extension-services/textile-testing-and-consultancy-centre"
              element={<TextileTesting />}
            />
            <Route
              path="/extension-services/scheme-of-community-development-through-polytechnics-cdtp"
              element={<Schemecommunity />}
            />

            <Route
              path="/extension-services/industry-institute-partnership-cell-iipc"
              element={<Partnershipcell />}
            />
            <Route
              path="/extension-services/indian-society-technical-education-iste"
              element={<Societytechnical />}
            />
            <Route
              path="/extension-services/institution-of-engineers-india-ie-i"
              element={<Institutionengineers />}
            />
            <Route
              path="/facilities/national-cadet-corps-ncc"
              element={<Ncc />}
            />
            <Route
              path="/facilities/national-service-scheme-nss"
              element={<NSS />}
            />
            <Route
              path="/facilities/industry-institute-partnership-cell-iipc"
              element={<Partnershipcell />}
            />

            <Route
              path="/facilities/entrepreneurship-development-cell"
              element={<Entrepreneurship />}
            />
            <Route
              path="/facilities/students-association&clubs"
              element={<Studandclubs />}
            />
            <Route
              path="/facilities/StudentsAssociation"
              element={<StudAssoc />}
            />

            <Route
              path="/facilities/clubs/ramanujam-club"
              element={<Ramanujamclub />}
            />
            <Route
              path="/facilities/clubs/english-club"
              element={<Englishclub />}
            />
            <Route
              path="/facilities/clubs/cvraman-club"
              element={<CVRamanclub />}
            />
            <Route
              path="/facilities/clubs/rotaract-club"
              element={<Rotaractclub />}
            />
            <Route
              path="/facilities/clubs/tamil-ilakkiya-mandram"
              element={<TamilElakkiyaManram />}
            />
            <Route
              path="/facilities/clubs/fine-arts-club"
              element={<Fineartsclub />}
            />
            <Route
              path="/facilities/clubs/students-counsel-club"
              element={<StudCounselclub />}
            />
            <Route
              path="/facilities/clubs/entrepreneurship-development-startup-club"
              element={<Entrestartupclub />}
            />
            <Route
              path="/facilities/clubs/clean-green-smart-club"
              element={<CleanGreenSmartclub />}
            />
            <Route
              path="/facilities/clubs/health-yoga-club"
              element={<HealthYogaclub />}
            />
            <Route
              path="/facilities/clubs/readers-club"
              element={<Readersclub />}
            />
            <Route
              path="/facilities/clubs/speakers-forum-club"
              element={<SpeakersForumclub />}
            />
            <Route
              path="/facilities/clubs/sports-games-club"
              element={<SportsGamesclub />}
            />
            <Route
              path="/facilities/clubs/placement-club"
              element={<Placementclub />}
            />
            <Route
              path="/facilities/clubs/women-in-development-club"
              element={<WomenDevelopmentclub />}
            />
            <Route path="/library" element={<Library />} />
            <Route path="/library/facilities" element={<Libfacilities />} />
            <Route path="/library/collections" element={<Libcollections />} />
            <Route path="/library/services" element={<Libservices />} />
            <Route path="/library/digital" element={<Libdigital />} />
            <Route path="/library/staff" element={<Libstaff />} />
            <Route path="/celebritys-view" element={<EminentVisitors />} />
            <Route path="/scholarship" element={<Scholarship />} />
            <Route path="/newsletter" element={<Newsletter />} />
            <Route path="/career" element={<Careers />} />
            <Route path="/online-help-desk" element={<HelpDesk />} />
            <Route path="/contact" element={<Contactpage />} />
            <Route
              path="/continuing-education-centre"
              element={<ContinueEdu />}
            />
            <Route
              path="/ciicp/online-courses"
              element={<ContinueEduOnline />}
            />
            <Route
              path="/ciicp/summer-course-brochure"
              element={<ContinueEduSummer />}
            />
            <Route
              path="/ciicp/skill-development"
              element={<ContinueEduskillDev />}
            />
            <Route
              path="/ciicp/popular-courses"
              element={<ContinueEduPopular />}
            />
            <Route path="/ciicp/faculty" element={<ContinueEduFaculty />} />
            <Route path="/ciicp/contact" element={<ContinueEduContact />} />
            <Route
              path="/ciicp/short-term-certificate-courses-for-school-students"
              element={<ContinueEduShorterm />}
            />
            <Route path="/sports" element={<Sportspage />} />
            <Route path="/department" element={<AdmissionCoursesoff />} />
            <Route path="/sports/achievements" element={<Sportspage />} />
            <Route
              path="/sports/about-the-department"
              element={<SportsAbout />}
            />
            <Route path="/sports/amenities" element={<SportsAminties />} />
            <Route path="/sports/staff" element={<Sportsstaff />} />
            <Route path="/placement" element={<Placementpage />} />
            <Route
              path="/placement/statistics"
              element={<PlacementStatistics />}
            />
            <Route path="/placement/contact" element={<PlacementContact />} />
            <Route path="/awards/:id" element={<Awardsinglepage />} />
            <Route path="/events/:id" element={<EventsViewSinglepage />} />
            <Route path="/news/:id" element={<NewsSinglepage />} />
            <Route path="/committees" element={<Committees />} />
            <Route
              path="/committees/grivance-committee"
              element={<Grivancecommittee />}
            />
            <Route
              path="/committees/grcommitteemember"
              element={<GRcommitteemember />}
            />
            <Route path="/committees/anti-ragging" element={<Antiragging />} />
            <Route
              path="/committees/anti-sexual-harrassment"
              element={<Antisexual />}
            />
            <Route path="/committees/anti-drugs" element={<Antidrugs />} />
            <Route
              path="/committees/nasha-mukt-hostel"
              element={<NashaMuktHostel />}
            />
            <Route
              path="/committees/iqac-committee"
              element={<IQACCommittee />}
            />
            <Route
              path="/committees/committee-for-sc-st"
              element={<SCSTCommittee />}
            />
            <Route
              path="/photography-competition-2023"
              element={<Photocompetition />}
            />
            <Route
              path="/collaboration/mou-signed"
              element={<Mousectioncontent />}
            />
            {/* <Route path="/submission-list" element={<Submissionlist/>}/> */}
            {/* <Route path="/job-openings" element={<Jobopenings />} /> */}
            <Route path="/blog" element={<Blogpage />} />
            {/* <Route path="/" element={<Home />} /> */}
          </Routes>
        </div>
        <Footer />
      </Suspense>
    </>
  );
}

export default App;
