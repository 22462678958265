import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function SearchComponent() {
  const [searchTerm, setSearchTerm] = useState("");
  const [allItems, setAllItems] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    // Fetch all items when the component mounts
    // This is just an example - adjust according to your data source
    const fetchItems = async () => {
      try {
        const response = await fetch("https://api.tpt.edu.in");
        const data = await response.json();
        setAllItems(data);
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };

    fetchItems();
  }, []);

  const handleSearch = async (e) => {
    e.preventDefault();

    // First, try local search
    const localResults = allItems.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (localResults.length > 0) {
      setSearchResults(localResults);
    } else {
      // If no local results, try API
      try {
        const response = await fetch(
          `https://your-api.com/search?q=${searchTerm}`
        );
        const data = await response.json();
        setSearchResults(data);
      } catch (error) {
        console.error("Error searching:", error);
        setSearchResults([]);
      }
    }
  };
  return (
    <form
      className="d-flex mt-3 mt-lg-0 searchform"
      role="search"
      onSubmit={handleSearch}
    >
      <div className="input-group">
        <input
          className="form-control border-end-0 border rounded-pill searchpl"
          type="search"
          placeholder="search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <span className="input-group-append">
          <button
            className="btn btn-outline-secondary bg-white border-bottom-0 border rounded-pill ms-n5"
            type="submit"
          >
            <i className="fa fa-search"></i>
          </button>
        </span>
      </div>
    </form>
  );
}

function Header() {
  function shoot(event) {
    event.stopPropagation();

    // eslint-disable-next-line no-undef
    $("#dropdownMenuLinkj").toggle();
  }
  function shoot1(event) {
    event.stopPropagation();

    // eslint-disable-next-line no-undef
    $("#multilevelDropdownMenu2").toggle();
  }

  return (
    <header className="w-100">
      <section className="container-fluid p-0 fixed-top">
        <div className="head-tag">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 p-0">
                <nav className="justify-content-center nav-header pb-xsm-0">
                  <Link to="/scholarship">
                    <i
                      className="fas fa-award text-warning"
                      aria-hidden="true"
                    ></i>{" "}
                    Scholarships{" "}
                  </Link>{" "}
                  <span className="divide-line"> &#8739;</span>
                  <Link to="/placement">
                    <i
                      className="fa fa-briefcase text-warning"
                      aria-hidden="true"
                    ></i>{" "}
                    Placement{" "}
                  </Link>
                  <Link to="/career">
                    <i
                      className="fa fa-briefcase text-warning"
                      aria-hidden="true"
                    ></i>{" "}
                    Career{" "}
                  </Link>
                  <Link to="https://sonatptalumni.org/" target="_blank">
                    <i
                      className="fa fa-briefcase text-warning"
                      aria-hidden="true"
                    ></i>{" "}
                    Alumni{" "}
                  </Link>
                </nav>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 p-0">
                <nav className="justify-content-center nav-header pb-xsm-0">
                  <Link to="/admission/brochure">
                    <i
                      className="fas fa-download text-warning"
                      aria-hidden="true"
                    ></i>{" "}
                    Download Brochure
                  </Link>
                </nav>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-6 col-12 p-0">
                <nav className="justify-content-center nav-header pt-xsm-0">
                  <a
                    href="https://www.tpt.edu.in/campus-360-virtual-tour/TPT_Aerialview/index.htm"
                    target="_blank"
                    className="mtsm-1"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1.2em"
                      height="1.2em"
                      viewBox="0 0 28 28"
                    >
                      <path
                        fill="#e6b610"
                        d="M14 3.5c.985 0 2.11.885 3.033 2.862c.416.892.762 1.953 1.014 3.138H9.953c.252-1.185.598-2.246 1.014-3.138C11.89 4.385 13.015 3.5 14 3.5M9.608 5.728C9.102 6.812 8.698 8.09 8.422 9.5H4.51a10.53 10.53 0 0 1 6.062-5.428a10 10 0 0 0-.964 1.656M8.183 11c-.12.96-.183 1.966-.183 3s.063 2.04.183 3H3.935a10.5 10.5 0 0 1-.435-3c0-1.043.152-2.05.435-3zm.239 7.5c.276 1.41.68 2.688 1.186 3.772c.28.599.601 1.16.964 1.656A10.53 10.53 0 0 1 4.51 18.5zm1.53 0h8.095c-.252 1.185-.598 2.246-1.014 3.138C16.11 23.615 14.985 24.5 14 24.5s-2.11-.885-3.033-2.862c-.416-.892-.762-1.953-1.014-3.138m8.353-1.5h-8.61a23 23 0 0 1-.195-3c0-1.045.069-2.051.195-3h8.61c.127.949.195 1.955.195 3s-.069 2.051-.195 3m1.273 1.5h3.912a10.53 10.53 0 0 1-6.062 5.428a10 10 0 0 0 .964-1.656c.506-1.084.91-2.363 1.186-3.772m4.487-1.5h-4.248c.12-.96.183-1.966.183-3s-.063-2.04-.183-3h4.248c.283.95.435 1.957.435 3s-.152 2.05-.435 3M17.428 4.072A10.53 10.53 0 0 1 23.49 9.5h-3.912c-.276-1.41-.68-2.688-1.186-3.772a10 10 0 0 0-.964-1.656M14 26c6.627 0 12-5.373 12-12S20.627 2 14 2S2 7.373 2 14s5.373 12 12 12"
                      />
                    </svg>
                    360° Virtual Tour
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-head-logo">
          <div className="container-fluid">
            <div className="row">
              <div className="col-2 text-end pe-0">
                <div className="brand-logo-section">
                  <Link to="/" className="brand-logo">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/images/logo.jpg"}
                      className="img-fluid"
                      title="TPT logo"
                      alt="logo"
                    />{" "}
                  </Link>
                </div>
              </div>
              <div className="col-8 pe-0">
                <div className="web-title">
                  <div className="clg-title">
                    <h1 className="clge-name">
                      Thiagarajar Polytechnic College
                    </h1>
                  </div>
                  <div className="sub-title">
                    <div className="left-10pes">
                      <span className="line-separator">|</span>
                      <span className="txt-glogo line1">
                        &nbsp;Govt Aided&nbsp;
                      </span>
                      <span className="line-separator">|</span>

                      <span className="txt-glogo line2">
                        &nbsp;Autonomous&nbsp;
                      </span>
                      <span className="line-separator">|</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-2 ps-0 pe-0">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/67-years-excellence.png"
                  }
                  className="img-fluid tpt-exp"
                  title="TPT 67 years excellence"
                  alt="67 years excellence"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="nav-tpt">
          <nav
            className="navbar navbar-expand-lg tpt-nav-bg"
            aria-label="Offcanvas navbar large"
          >
            <div className="container-fluid">
              <div className="col-lg-12 col-12 text-end">
                <button
                  className="navbar-toggler menu-off-icon"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasNavbar2"
                  aria-controls="offcanvasNavbar2"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="offcanvas offcanvas-end bg-off-canva"
                  id="offcanvasNavbar2"
                  aria-labelledby="offcanvasNavbar2Label"
                >
                  <div className="offcanvas-header">
                    <button
                      type="button"
                      className="btn-close btn-close-white"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="offcanvas-body">
                    <ul className="navbar-nav justify-content-center flex-grow-1 pe-3">
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/"
                        >
                          Home
                        </Link>
                      </li>
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle"
                          to="/#ff"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          About us
                        </Link>
                        <ul className="dropdown-menu">
                          <li>
                            <Link
                              className="dropdown-item"
                              to={process.env.PUBLIC_URL + "/about/history"}
                            >
                              History
                            </Link>{" "}
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to={
                                process.env.PUBLIC_URL + "/about/vision-mission"
                              }
                            >
                              Vision & Mission
                            </Link>{" "}
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to={process.env.PUBLIC_URL + "/about/management"}
                            >
                              Management
                            </Link>{" "}
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to={
                                process.env.PUBLIC_URL +
                                "/about/sri-karumuttu-thiagarajar-endowment-fund"
                              }
                            >
                              Sri Karumuttu Thiagarajar Endowment Fund
                            </Link>{" "}
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to={process.env.PUBLIC_URL + "/about/director"}
                            >
                              Director
                            </Link>{" "}
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to={process.env.PUBLIC_URL + "/about/principal"}
                            >
                              Principal
                            </Link>{" "}
                          </li>

                          {/* <li>
                            <Link
                              className="dropdown-item"
                              to={
                                process.env.PUBLIC_URL +
                                "/about/principal-message"
                              }
                            >
                              Principal's Message
                            </Link>
                          </li> */}
                          <li>
                            <Link
                              className="dropdown-item"
                              to={process.env.PUBLIC_URL + "/about/momentous"}
                            >
                              A Momentous Journey
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to={process.env.PUBLIC_URL + "/about/newsletter"}
                            >
                              Newsletter
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle"
                          to={process.env.PUBLIC_URL + "/admission"}
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Admissions
                        </Link>
                        <ul className="dropdown-menu">
                          <li>
                            <Link
                              className="dropdown-item"
                              to={
                                process.env.PUBLIC_URL +
                                "/admission/courses-offered"
                              }
                            >
                              Courses Offered
                            </Link>{" "}
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to={
                                process.env.PUBLIC_URL + "/admission/procedure"
                              }
                            >
                              Admission Procedure
                            </Link>{" "}
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              to={
                                process.env.PUBLIC_URL + "/admission/brochure"
                              }
                            >
                              Brochure
                            </Link>{" "}
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to={process.env.PUBLIC_URL + "/admission/contact"}
                            >
                              Contact
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle"
                          to={process.env.PUBLIC_URL + "/placement"}
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Placement
                        </Link>
                        <ul className="dropdown-menu">
                          <li>
                            <Link
                              className="dropdown-item"
                              to={process.env.PUBLIC_URL + "/placement/"}
                            >
                              Introduction
                            </Link>{" "}
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to={
                                process.env.PUBLIC_URL + "/placement/statistics"
                              }
                            >
                              Placement Statistics
                            </Link>{" "}
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to={process.env.PUBLIC_URL + "/placement/contact"}
                            >
                              Contact
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle"
                          to={process.env.PUBLIC_URL + "/department"}
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Department
                        </Link>
                        <ul className="dropdown-menu" id="deptmt">
                          {/* <li><Link className="dropdown-item"
                                                                                    to="/department/administration-tpt">Administration</Link></li> */}
                          <li>
                            <Link
                              className="dropdown-item"
                              to={
                                process.env.PUBLIC_URL +
                                "/department/civil-engineering/about-the-department"
                              }
                            >
                              Civil Engineering
                            </Link>
                          </li>
                          <li className="dropdown dropend show">
                            <Link
                              className="dropdown-item dropdown-toggle"
                              role="button"
                              id="dropdownMenuLink"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              onClick={shoot}
                            >
                              Mechanical Engineering
                            </Link>
                            <ul
                              className="dropdown-menu"
                              id="dropdownMenuLinkj"
                            >
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/department/mechanical-engineering-aided/about-the-department"
                                  }
                                >
                                  Aided
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/department/mechanical-engineering/about-the-department"
                                  }
                                >
                                  Self-supporting
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="nav-item dropdown dropend">
                            <Link
                              className="dropdown-item dropdown-toggle"
                              role="button"
                              data-bs-toggle="dropdown"
                              onClick={shoot1}
                            >
                              Electrical &amp; Electronics Engineering
                            </Link>
                            <ul
                              className="dropdown-menu"
                              id="multilevelDropdownMenu2"
                            >
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/department/electrical-electronics-engineering-aided/about-the-department"
                                  }
                                >
                                  Aided
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/department/electrical-electronics-engineering/about-the-department"
                                  }
                                >
                                  Self-supporting
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/department/production-engineering/about-the-department"
                            >
                              Production Engineering
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/department/textile-technology/about-the-department"
                            >
                              Textile Technology
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/department/computer-engineering/about-the-department"
                            >
                              Computer Engineering
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/department/computer-science-information-technology/about-the-department"
                            >
                              Computer Science & Information Technology
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/department/electronics-communication-engineering/about-the-department"
                            >
                              Electronics and Communication Engineering
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/department/architectural-engineering/about-the-department"
                            >
                              Architecture
                            </Link>
                          </li>
                          {/* <li><Link className="dropdown-item"
                                                                                    to="/agriculutural-technology">Agricultural -
                                                                                    Technology</Link></li> */}
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/department/artificial-intelligence-machine-learning/about-the-department"
                            >
                              Artificial Intelligence (AI) and Machine Learning
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/department/science-humanities/about-the-department"
                            >
                              Science and Humanities
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/examination">
                          Exams
                        </Link>
                      </li>
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle"
                          to="/#ext"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Ext.Services
                        </Link>
                        <ul className="dropdown-menu">
                          <h5 className="ps-3 pt-2">
                            Testing and Consultancy Services
                          </h5>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/extension-services/centre-for-civil-testing-and-consultancy"
                            >
                              Center for Civil Testing and Consultancy
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              to="/extension-services/textile-testing-and-consultancy-centre"
                            >
                              Center for Textile Testing and Consultancy
                            </Link>
                          </li>

                          <h5 className="ps-3 pt-2">Professional Bodies</h5>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/extension-services/indian-society-technical-education-iste"
                            >
                              Indian Society for Technical Education (ISTE)
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              to="/extension-services/institution-of-engineers-india-ie-i"
                            >
                              Institution of Engineers (India) - IE(I)
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle"
                          to="/#gg"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Facilities
                        </Link>
                        <ul className="dropdown-menu">
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/facilities/national-cadet-corps-ncc"
                            >
                              National Cadet Corps (NCC)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/facilities/national-service-scheme-nss"
                            >
                              National Service Scheme (NSS)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/facilities/entrepreneurship-development-cell"
                            >
                              Entrepreneurship Development Cell
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/facilities/industry-institute-partnership-cell-iipc"
                            >
                              Industry Institute Partnership Cell (IIPC)
                            </Link>
                          </li>

                          <li className="dropdown dropend show">
                            <Link
                              className="dropdown-item"
                              to="/facilities/StudentsAssociation"
                            >
                              Students Association & Clubs
                            </Link>
                          </li>
                          <li className="dropdown dropend show">
                            <Link
                              className="dropdown-item"
                              to="/facilities/counselling-monitoring"
                            >
                              Counselling and Mentoring System
                            </Link>
                          </li>
                          <li className="dropdown dropend show">
                            <Link
                              className="dropdown-item"
                              to="/facilities/tpt-alumni"
                            >
                              TPT Alumni Auditorium
                            </Link>
                          </li>

                          <li className="dropdown dropend show">
                            <Link
                              className="dropdown-item"
                              to="/facilities/solid-waste-management-system"
                            >
                              SWMS - Vermicomposting
                            </Link>
                          </li>
                          <li className="dropdown dropend show">
                            <Link
                              className="dropdown-item"
                              to="/facilities/hostel"
                            >
                              Hostel
                            </Link>
                          </li>
                        </ul>
                      </li>

                      {/* <ul
                              className="dropdown-menu"
                              id="dropdownMenuLinkj"
                            >
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/tpt-clubs/youth-red-cross-yrc"
                                >
                                  Youth Red Cross (YRC)
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/tpt-clubs/women-development"
                                >
                                  Women in Development (WID)
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/tpt-clubs/eco-club"
                                >
                                  Eco Club
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/tpt-clubs/english-club"
                                >
                                  English Club
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/tpt-clubs/career-guidance-placement"
                                >
                                  Career Guidance and Placement
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/tpt-clubs/tamil-ilakkiya-mandram"
                                >
                                  Tamil Ilakkiya Mandram
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/tpt-clubs/fine-arts-club"
                                >
                                  Fine Arts Club
                                </Link>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li> */}
                      <li className="nav-item">
                        <Link className="nav-link" to="/library">
                          Library
                        </Link>
                      </li>
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle"
                          to="/ciicp"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          CIICP
                        </Link>
                        <ul className="dropdown-menu">
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/continuing-education-centre"
                            >
                              Continuing Education Centre
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/sports/about-the-department"
                        >
                          Sports
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/contact">
                          Contact
                        </Link>
                      </li>
                    </ul>
                    <SearchComponent />
                    {/* <form
                      className="d-flex mt-3 mt-lg-0 searchform"
                      role="search"
                    >
                      <div className="input-group">
                        <input
                          className="form-control border-end-0 border rounded-pill searchpl"
                          type="search"
                          id="example-search-input"
                          placeholder="search..."
                        />
                        <span className="input-group-append">
                          <button
                            className="btn btn-outline-secondary bg-white border-bottom-0 border rounded-pill ms-n5"
                            type="button"
                          >
                            <i className="fa fa-search"></i>
                          </button>
                        </span>
                      </div>
                    </form> */}
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </section>
    </header>
  );
}

export default Header;
